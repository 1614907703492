import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';

import {BarraSuperiorComponent} from './barra-superior/barra-superior.component';
import {MenuLateralComponent} from './menu-lateral/menu-lateral.component';
import {PieDePaginaComponent} from './pie-de-pagina/pie-de-pagina.component';
import {BreadcrumbComponent} from './breadcrumb/breadcrumb.component';

import {GlobalRoutingModule} from './global-routing.module';

import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { SkeletonDashboardComponent } from './skeleton-dashboard/skeleton-dashboard.component';
import { SistemaService } from '../shared/services/operation/sistema.services';
import { PermisosService } from '../shared/services/operation/permisos.service';
import { EntidadesService } from '../shared/services/operation/entidades.service';

@NgModule({
  imports: [
    CommonModule,
    // ROUTING
    GlobalRoutingModule,
    // NGBOOTSTRAP
    NgbModule.forRoot(),
  ],
  declarations: [
    PageNotFoundComponent,
    SkeletonDashboardComponent,
    BarraSuperiorComponent,
    MenuLateralComponent,
    PieDePaginaComponent,
    BreadcrumbComponent
  ],
  exports: [
    // NGBOOTSTRAP
    PageNotFoundComponent,
    SkeletonDashboardComponent,
    BarraSuperiorComponent,
    MenuLateralComponent,
    PieDePaginaComponent,
    BreadcrumbComponent
  ],
  providers: [
      SistemaService,
      PermisosService,
      EntidadesService
  ]
})
export class GlobalModule { }
