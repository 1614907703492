import {Component, HostListener, OnInit} from '@angular/core';
import * as $ from 'jquery/dist/jquery';
import { Subject } from 'rxjs';
import { CredencialesService } from 'src/app/shared/services/operation/credenciales.service';
import { PermisosService } from 'src/app/shared/services/operation/permisos.service';
import { SistemaService } from 'src/app/shared/services/operation/sistema.services';

import * as config from '../../shared/services/configurations';


@Component({
  selector: 'skeleton-dashboard',
  templateUrl: './skeleton-dashboard.component.html'
})
export class SkeletonDashboardComponent implements OnInit {

  menu: any[];
  private isCollapsedObservable = new Subject<boolean>();

  constructor(private credencialService: CredencialesService,
              private sistemaService: SistemaService,
              private permisosService: PermisosService) {
    this.menu = config.MENU;
  }

  getIsCollapsedEvent() {
    return this.isCollapsedObservable.asObservable();
  }

  changeMenu(ev: boolean) {
    if (ev) {
      this.menu.forEach(item => {
        if (item.childs) { item.isCollapsed = false; }
      });
      this.isCollapsedObservable.next(false);
    }
  }

  ngOnInit() {
    if ($(document).width() < 1024) {
      $('body').addClass('enlarged');
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (event.target.innerWidth < 1024) {
      $('body').addClass('enlarged');
    } else {
      $('body').removeClass('enlarged');
    }
  }
}
