import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Mensajes } from 'src/app/shared/interfaces/mensajes';
import { Rol } from 'src/app/shared/model/rol';
import { Sistema } from 'src/app/shared/model/sistema';
import { SistemaRol } from 'src/app/shared/model/sistemaRol';
import { UsuarioNuevo } from 'src/app/shared/model/UsuarioNuevo';
import { KeycloakService } from 'src/app/shared/services/keycloak/keycloak.service';
import { CredencialesService } from 'src/app/shared/services/operation/credenciales.service';
import { RolesService } from 'src/app/shared/services/operation/roles.service';
import { UsuarioService } from 'src/app/shared/services/operation/usuario.service';
import { UtilsService } from 'src/app/shared/services/operation/utils.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-roles-usuario-servicio',
  templateUrl: './roles-usuario-servicio.component.html',
  styleUrls: ['./roles-usuario-servicio.component.css']
})
export class RolesUsuarioServicioComponent implements OnInit {

  rolesForm: FormGroup;
    spinner = false;
    sistemas: Array<Sistema> = [];
    roles: Array<Rol> = [];
    sistemaRoles: Array<SistemaRol> = [];
    usuarioNuevo: UsuarioNuevo;

    optionsDataTable: any = {
        paging: false,
        info: false,
        sortable: false,
        ordering: true,
        searching: false,
        scrollY: '50vh',
        scrollCollapse: true,
        language: {
            search: Mensajes.ETIQUETA_BUSCAR,
            emptyTable: Mensajes.ETIQUETA_TABLAVACIA
        }
    };

    // labels
    tituloRoles: string = Mensajes.ETIQUETA_TITULO_ROLES;
    lblSistema: string = Mensajes.ETIQUETA_SISTEMA;
    lblRol: string = Mensajes.ETIQUETA_ROL;
    lblCancelar: string = Mensajes.ETIQUETA_CANCELAR;
    lblQuitar: string = Mensajes.ETIQUETA_QUITAR;

    constructor(private formBuilder: FormBuilder,
                private creencialService: CredencialesService,
                private rolesService: RolesService,
                private toastr: ToastrService,
                private utils: UtilsService,
                private usuarioService: UsuarioService) {
    }

    ngOnInit() {
        if (this.creencialService.validaLogin()) {

            this.rolesForm = this.formBuilder.group({
                sistema: [1, Validators.required],
                rol: [1, Validators.required]
            });

            this.usuarioNuevo = this.usuarioService.getNuevoUsuarioServicio();

            if (this.usuarioNuevo != null || this.usuarioNuevo !== undefined) {

                if (this.usuarioNuevo.sistemasRoles !== undefined) {
                    this.sistemaRoles = this.usuarioNuevo.sistemasRoles;
                }

                this.sistemas = this.comparaSistemas(this.creencialService.getCredencial().sistemas);
                this.sistemas = this.sistemas.filter( sistema => sistema.nombreCorto !== 'SCI_FRONT');

                if (this.sistemas.length > 0) {
                    this.spinner = true;
                    this.rolesService.obtenerRoles(this.sistemas[0].id)
                        .subscribe(data => {
                                this.roles = data.data;
                                this.roles = this.roles.filter(rol => rol.esAdministrador === false);
                                this.rolesForm = this.formBuilder.group({
                                    sistema: [this.sistemas[0].id, Validators.required],
                                    rol: [this.roles[0].idRol, Validators.required]
                                });
                                this.spinner = false;
                            }
                            , error => {
                                const status = error.status;
                                switch (status) {
                                    case 400:
                                        this.utils.mostrarError(error.error.description);
                                        break;
                                    case 401:
                                        this.utils.mostrarError(Mensajes.NO_PERMITIDO);
                                        break;
                                    case 403:
                                        this.utils.mostrarError(Mensajes.PROHIBIDO);
                                        break;
                                    case 404:
                                        this.utils.mostrarError(Mensajes.NO_ENCONTRADO);
                                        break;
                                    default:
                                        console.log(error);
                                        this.utils.mostrarError(Mensajes.NO_ENCONTRADO);
                                        break;
                                }
                                this.spinner = false;
                            });
                } else {
                    this.spinner = false;
                    this.toastr.error(Mensajes.NO_SISTEMAS, 'Error!!!');
                }
            } else {
                this.toastr.error(Mensajes.DATOS_INCOMPLETOS, 'Error!!!');
                var element: HTMLElement = document.getElementById('datos-usuario-servicio') as HTMLElement;
                element.click();
            }
        } else {
            KeycloakService.logout();
        }
    }

    selectChangeHandler(event: any) {
        this.spinner = true;
        this.rolesService.obtenerRoles(event.target.value)
            .subscribe(data => {
                    this.roles = data.data;
                    this.roles = this.roles.filter(rol => rol.esAdministrador === false);
                    this.rolesForm = this.formBuilder.group({
                        sistema: [event.target.value, Validators.required],
                        rol: [this.roles[0].idRol, Validators.required]
                    });
                    this.spinner = false;
                }
                , error => {
                    const status = error.status;
                    switch (status) {
                        case 400:
                            this.utils.mostrarError(error.error.description);
                            break;
                        case 401:
                            this.utils.mostrarError(Mensajes.NO_PERMITIDO);
                            break;
                        case 403:
                            this.utils.mostrarError(Mensajes.PROHIBIDO);
                            break;
                        case 404:
                            this.utils.mostrarError(Mensajes.NO_ENCONTRADO);
                            break;
                        default:
                            console.log(error);
                            this.utils.mostrarError(Mensajes.NO_ENCONTRADO);
                            break;
                    }
                    this.spinner = false;
                });
    }

    agregarRol() {

        let sisId: number = this.rolesForm.get('sistema').value;
        let rolId: number = this.rolesForm.get('rol').value;
        let repetido = false;
        
        if (this.sistemaRoles.length > 0) {
            for (let sisr of this.sistemaRoles) {
                if (sisr.idSistema == sisId) {
                    repetido = true;
                }
            }
        }

        if (!repetido) {
            let sistemaRol: SistemaRol = new SistemaRol();
            sistemaRol.idSistema = sisId;
            sistemaRol.idRol = rolId;

            for (let sis of this.sistemas) {
                if (sis.id == sisId) {
                    sistemaRol.descSistema = sis.descripcion;
                }
            }

            for (let rol of this.roles) {
                if (rol.idRol == rolId) {
                    sistemaRol.descRol = rol.descripcion;
                }
            }

            this.sistemaRoles.push(sistemaRol);
        } else {
            this.toastr.warning(Mensajes.SISTEMA_REPETIDO, 'Oops!!');
        }
    }

    quitarRol(rol: any) {
        const index = this.sistemaRoles.indexOf(rol, 0);
        this.sistemaRoles.splice(index, 1);
    }

    regresar() {
        this.usuarioNuevo.sistemasRoles = this.sistemaRoles;
        this.usuarioService.setNuevoUsuarioServicio(this.usuarioNuevo);
        const element: HTMLElement = document.getElementById('datos-usuario-servicio') as HTMLElement;
        element.click();
    }

    onSubmit() {
        this.usuarioNuevo.sistemasRoles = this.sistemaRoles;
        this.usuarioService.setNuevoUsuarioServicio(this.usuarioNuevo);
        const element: HTMLElement = document.getElementById('resumen-usuario-servicio') as HTMLElement;
        element.click();
    }

    comparaSistemas(sisUsuario: Array<any>): Array<Sistema> {
        const sistemasSci = environment.sistemas;
        const sisTmp: Array<Sistema> = [];
        sistemasSci.filter(item => {
            sisUsuario.filter(itemu => {
                if (item === itemu.nombreCorto) {
                    sisTmp.push(itemu);
                }
            });
        });
        return sisTmp;
    }

}
