export class TipoUsuario {

  idTipoUsuario: number;
  nombreCorto: string;
  descripcion: string;

  constructor(id: number) {
    this.idTipoUsuario = id;
  }

}
