import {Component} from '@angular/core';
import { CredencialesService } from './shared/services/operation/credenciales.service';
import { PermisosService } from './shared/services/operation/permisos.service';
import { SistemaService } from './shared/services/operation/sistema.services';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  title = 'app';
  condicion = true;

  constructor(private permisoService: PermisosService,
              private credencialService: CredencialesService,
              private sistemaService: SistemaService) {

  }

}
