import {BrowserModule} from '@angular/platform-browser';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {AppComponent} from './app.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';

import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import { InicioRoutingModule } from './inicio/inicio-routing.module';
import { GlobalRoutingModule } from './global/global-routing.module';
import { ConsultaRoutingModule } from './consulta/consulta-routing.module';
import { AdministracionRoutingModule } from './administracion/administracion-routing.module';
import { AltaUsuariosRoutingModule } from './alta-usuarios/alta-usuarios-routing.module';
import { GlobalModule } from './global/global.module';
import { InicioModule } from './inicio/inicio.module';
import { AltaUsuariosModule } from './alta-usuarios/alta-usuarios.module';
import { ConsultaModule } from './consulta/consulta.module';
import { AdministracionModule } from './administracion/administracion.module';
import { CredencialesService } from './shared/services/operation/credenciales.service';
import { InterceptorProvider } from './shared/services/interceptor/interceptor';

@NgModule({
  declarations: [
    AppComponent
  ],
  exports: [
    HttpClientModule,
    // GlobalModule
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    NgbModule,
    InicioRoutingModule,
    // GlobalRoutingModule,
    // ConsultaRoutingModule,
    // AdministracionRoutingModule,
    // AltaUsuariosRoutingModule,
    GlobalModule,
    InicioModule,
    AltaUsuariosModule,
    ConsultaModule,
    AdministracionModule
  ],
  providers: [
    CredencialesService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorProvider,
      multi: true,
    }
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],

  bootstrap: [AppComponent]
})
export class AppModule {
}
