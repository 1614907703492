import {Sistema} from './sistema';
import {Permiso} from './permiso';
import {Facultad} from './facultad';

export class ObjetosUsuario {

  username: string;
  sistema: Sistema;
  permiso: Permiso;
  universo: Array<Facultad>;

  constructor() {}

}
