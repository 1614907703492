//Librerias
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
//Modelo
import {Credencial} from '../../model/credencial';
//Servicios
import {UtilsService} from './utils.service';
import {KeycloakService} from '../keycloak/keycloak.service';


@Injectable({
  providedIn: 'root'
})
export class CredencialesService {

  credencial: Credencial;

  constructor(private http: HttpClient,
              private utils: UtilsService) {


  }

  login(): Credencial {

    //OBTENER TOKEN
    let token = sessionStorage.getItem('token');
    if (token == null) {
      this.credencial = new Credencial();
      this.credencial.token = KeycloakService.getTokenAuth();
      sessionStorage.setItem('token', this.credencial.token);
    }

    return this.credencial;
  }

  validaLogin(){
    if (this.getCredencial() != null) {
      return (this.getCredencial().sistemas != undefined);
    } else {
      return false;
    }
  }

  getCredencial(): Credencial {
    return this.credencial;
  }

  setCredencial(credencial: Credencial){
    this.credencial = credencial;
  }
}
