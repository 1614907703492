import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {CredencialesService} from './credenciales.service';
import { environment } from 'src/environments/environment';
import { timeout } from 'rxjs/operators';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json'
  }),
  withCredentials: false
};

@Injectable({
  providedIn: 'root'
})
export class PermisosService {

  constructor(private http: HttpClient,
              private credencialServices: CredencialesService) {}

  obtenerPermisos(usrname: string, sistema: number) {

    let predicado = '';
    predicado = predicado.concat('/sistemas/');
    predicado = predicado.concat(sistema.toString());
    predicado = predicado.concat('/usuarios/');
    predicado = predicado.concat(usrname);
    predicado = predicado.concat('/permisos/');

    const endpoint = environment.seguridadEndpoint + predicado;

    return this.http.get<any>(endpoint);
  }

  obtenerPermisosRol(sistema: number, rol: number) {
    let predicado = '';
    predicado = predicado.concat('/sistemas/');
    predicado = predicado.concat(sistema.toString());
    predicado = predicado.concat('/roles/');
    predicado = predicado.concat(rol.toString());
    predicado = predicado.concat('/permisos/');

    const endpoint = environment.seguridadEndpoint + predicado;
    return this.http.get<any>(endpoint);
  }

  async asociaPermiso(sistema: number, username: string, permiso: number) {

    let predicado = '';
    predicado = predicado.concat('/sistemas/');
    predicado = predicado.concat(sistema.toString());
    predicado = predicado.concat('/usuarios/');
    predicado = predicado.concat(username);
    predicado = predicado.concat('/permisos/');
    predicado = predicado.concat(permiso.toString());

    const endpoint = environment.seguridadEndpoint + predicado;
    return this.http.post<any>(endpoint, null, httpOptions).toPromise();

  }
}
