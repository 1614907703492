// Librerias
import {Injectable} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
// Modelo
import {Mensajes} from '../../interfaces/mensajes';
import {KeycloakService} from '../keycloak/keycloak.service';
import {Router} from "@angular/router";


@Injectable()
export class UtilsService {

  constructor(private toastr: ToastrService,
              private router: Router) { }


    mostrarError(message: string) {
    let error = '';
    if ( message.indexOf('Http failure', 0) === 0 ) {
      error = Mensajes.CERRANDO_SESION;
      this.router.navigate(['/main-dashboard']);
      KeycloakService.logout();
    } else {
      error = message;
    }

    this.toastr.error(error, 'Error!!!');

  }

  mostarWarning(message: string) {
    this.toastr.warning(message);
  }
  mostrarAviso(message: string) {
    this.toastr.info(message);
  }

}

