import { Facultad } from './facultad';
import { Permiso } from './permiso';


export class SistemaRol {

  idSistema: number;
  idRol: number;
  descSistema: string;
  descRol: string;
  facultades?: Array<Facultad>;
  permiso?: Permiso;


}
