import {Component, OnInit} from '@angular/core';
import {UsuarioService} from '../../shared/services/operation/usuario.service';
import {Usuario} from '../../shared/model/usuario';
import {CredencialesService} from '../../shared/services/operation/credenciales.service';
import {Mensajes} from '../../shared/interfaces/mensajes';
import {Router} from '@angular/router';
import {SistemaRol} from '../../shared/model/sistemaRol';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {RolesService} from '../../shared/services/operation/roles.service';
import {UtilsService} from '../../shared/services/operation/utils.service';
import {Rol} from '../../shared/model/rol';
import {Sistema} from '../../shared/model/sistema';
import {ToastrService} from 'ngx-toastr';
import {SistemaService} from '../../shared/services/operation/sistema.services';
import swal from 'sweetalert2';
import {PermisosService} from '../../shared/services/operation/permisos.service';
import {Location} from '@angular/common';
import {TipoUsuario} from '../../shared/model/tipoUsuario';
import { environment } from 'src/environments/environment';
import { UsuarioServicioWrapper } from 'src/app/shared/model/usuario-servicio-wrapper';

@Component({
    selector: 'app-sistemas',
    templateUrl: './sistemas.component.html'
})
export class SistemasComponent implements OnInit {

    spinner = false;
    usuarioElegido: Usuario;
    usuariosInactivos: Usuario[];
    sistemaRoles: Array<SistemaRol> = [];
    rolesForm: FormGroup;
    roles: Array<Rol> = [];
    sistemas: Array<Sistema> = [];
    sistemasTmp: Array<SistemaRol> = [];
    sistemasActuales: Array<Sistema> = [];
    usuarioId;

    // labels
    lblSistema: string = Mensajes.ETIQUETA_SISTEMA;
    lblRol: string = Mensajes.ETIQUETA_ROL;
    lblCancelar: string = Mensajes.ETIQUETA_CANCELAR;
    lblQuitar: string = Mensajes.ETIQUETA_QUITAR;
    lblSistemaAct: string = Mensajes.ETIQUETA_SISTEMA_ACTUAL;
    header: string = Mensajes.ETIQUETA_ADMIN_SISTEMAS;
    badge: string = Mensajes.BADGE_ADMIN_SISTEMAS;

    optionsDataTable: any = {
        paging: false,
        info: false,
        sortable: false,
        ordering: true,
        searching: false,
        scrollY: '50vh',
        scrollCollapse: true,
        language: {
            search: Mensajes.ETIQUETA_BUSCAR,
            emptyTable: Mensajes.ETIQUETA_TABLAVACIA
        }
    };

    constructor(private usuarioService: UsuarioService,
                private credencialService: CredencialesService,
                private permisosService: PermisosService,
                private sistemaService: SistemaService,
                private rolesService: RolesService,
                private utils: UtilsService,
                private formBuilder: FormBuilder,
                private toastr: ToastrService,
                private router: Router,
                private _location: Location) {
    }

    ngOnInit() {
        if (this.credencialService.validaLogin()) {
            this.usuarioElegido = this.usuarioService.getUsuario();
            this.usuariosInactivos = this.usuarioService.getUsuariosInactivos();
            this.rolesForm = this.formBuilder.group({
                sistema: [1, Validators.required],
                rol: [1, Validators.required]
            });

            this.sistemaService.obtenerSistemas(this.usuarioElegido.username)
                .subscribe(data => {
                  this.sistemasActuales = this.comparaSistemas(data.data);
                });

            this.sistemas = this.comparaSistemas(this.credencialService.getCredencial().sistemas);

            if (this.sistemas.length > 0) {
                this.spinner = true;
                this.rolesService.obtenerRoles(this.sistemas[0].id)
                    .subscribe(data => {
                            this.roles = data.data;
                            this.roles = this.roles.filter( rol => rol.esAdministrador === false );
                            this.rolesForm = this.formBuilder.group({
                                sistema: [this.sistemas[0].id, Validators.required],
                                rol: [this.roles[0].idRol, Validators.required]
                            });
                            this.spinner = false;
                        }
                        , error => {
                            const status = error.status;
                            switch (status) {
                                case 400:
                                    this.utils.mostrarError(error.error.description);
                                    break;
                                case 401:
                                    this.utils.mostrarError(Mensajes.NO_PERMITIDO);
                                    break;
                                case 403:
                                    this.utils.mostrarError(Mensajes.PROHIBIDO);
                                    break;
                                case 404:
                                    this.utils.mostrarError(Mensajes.NO_ENCONTRADO);
                                    break;
                                default:
                                    console.log(error);
                                    this.utils.mostrarError(Mensajes.NO_ENCONTRADO);
                                    break;
                            }
                            this.spinner = false;
                        });
            } else {
                this.spinner = false;
                this.toastr.error(Mensajes.NO_SISTEMAS, 'Error!!!');
            }
        } else {
            this.router.navigate(['/']);
        }
    }

    cancelar() {
        this._location.back();
    }

    selectChangeHandler(event: any) {
        this.spinner = true;
        this.rolesService.obtenerRoles(event.target.value)
            .subscribe(data => {
                    this.roles = data.data;
                    this.roles = this.roles.filter( rol => rol.esAdministrador === false );
                    this.rolesForm = this.formBuilder.group({
                        sistema: [event.target.value, Validators.required],
                        rol: [this.roles[0].idRol, Validators.required]
                    });
                    this.spinner = false;
                }
                , error => {
                    const status = error.status;
                    switch (status) {
                        case 400:
                            this.utils.mostrarError(error.error.description);
                            break;
                        case 401:
                            this.utils.mostrarError(Mensajes.NO_PERMITIDO);
                            break;
                        case 403:
                            this.utils.mostrarError(Mensajes.PROHIBIDO);
                            break;
                        case 404:
                            this.utils.mostrarError(Mensajes.NO_ENCONTRADO);
                            break;
                        default:
                            console.log(error);
                            this.utils.mostrarError(Mensajes.NO_ENCONTRADO);
                            break;
                    }
                    this.spinner = false;
                });
    }

    agregarRol() {
        const sis = this.rolesForm.get('sistema').value;
        const rol = this.rolesForm.get('rol').value;
        let repetido = false;

        if (this.sistemaRoles.length > 0) {
            for (const sisr of this.sistemaRoles) {
                if (sisr.idSistema == sis) {
                    repetido = true;
                    break;
                }
            }
        }

        for (const sisrr of this.sistemasActuales) {
            if (sis == sisrr.id) {
                repetido = true;
            }
        }

        if (!repetido) {
            let sistemaRol: SistemaRol = new SistemaRol();
            sistemaRol.idSistema = sis;
            sistemaRol.idRol = rol;

            for (let sis of this.sistemas) {
                if (sis.id == sistemaRol.idSistema) {
                    sistemaRol.descSistema = sis.descripcion;
                }
            }

            for (let rol of this.roles) {
                if (rol.idRol == sistemaRol.idRol) {
                    sistemaRol.descRol = rol.descripcion;
                }
            }

            this.consultaFacultades(sistemaRol);

        } else {
            this.toastr.warning(Mensajes.SISTEMA_REPETIDO, 'Oops!!');
        }
    }

    quitarRol(rol: any) {
        const index = this.sistemaRoles.indexOf(rol, 0);
        this.sistemaRoles.splice(index, 1);
    }

    adminSistema(sisActual: Sistema) {
        if (sisActual.estatus) {
            swal({
                text: '¿Desactivar Sistema ' + sisActual.descripcion + '?',
                type: 'question',
                confirmButtonText: 'Aceptar',
                cancelButtonText: 'Cancelar',
                showCancelButton: true,
                focusCancel: false,
                allowOutsideClick: false,
                showLoaderOnConfirm: true,
                preConfirm: (result) => {
                    if (result) {
                        this.spinner = true;
                        this.sistemaService.borrarSistemas(this.usuarioElegido.username, sisActual.id)
                            .subscribe(data => {
                                swal({
                                    type: 'success',
                                    title: 'Listo',
                                    text: Mensajes.OPERACION_EXITOSA
                                }).then(() => {
                                    this.spinner = false;
                                });
                            }, error1 => {
                                let texto;
                                const status = error1.status;
                                switch (status) {
                                    case 400:
                                        texto = error1.error.description;
                                        break;
                                    case 401:
                                        texto = Mensajes.NO_PERMITIDO;
                                        break;
                                    case 403:
                                        texto = Mensajes.PROHIBIDO;
                                        break;
                                    case 404:
                                        texto = Mensajes.NO_ENCONTRADO;
                                        break;
                                    case 406:
                                        texto = Mensajes.NO_ACEPTADO;
                                        break;
                                    case 422:
                                        texto = Mensajes.INFO_INCORRECTA;
                                        break;
                                    default:
                                        console.log(error1);
                                        texto = Mensajes.ERROR_SOLICITUD;
                                        break;
                                }
                                this.spinner = false;
                                swal({
                                    type: 'error',
                                    title: 'Error',
                                    text: texto
                                });
                            });
                    }
                }
            }).then(
                result => {
                    if (result.dismiss !== undefined ) {
                        sisActual.estatus = !sisActual.estatus;
                    }
                }
            );
        }
    }

    onSubmit() {
        swal({
            text: '¿Guardar Usuario?',
            type: 'question',
            confirmButtonText: 'Aceptar',
            cancelButtonText: 'Cancelar',
            showCancelButton: true,
            focusCancel: false,
            allowOutsideClick: false,
            showLoaderOnConfirm: true,
            preConfirm: async (result): Promise<UsuarioServicioWrapper> => {
              if (result) {
                this.spinner = true;
                let response = new UsuarioServicioWrapper();
                try {
                  for (const sistema of this.sistemaRoles) {
                    const usrTmp = JSON.parse(JSON.stringify(this.usuarioElegido));
                    usrTmp.rol = JSON.parse(JSON.stringify(new Rol(sistema.idRol)));
                    usrTmp.tipUsuario = JSON.parse(JSON.stringify(new TipoUsuario(environment.tipoUSuarioSWS)));
                    usrTmp.apellidoPaterno = null;
                    usrTmp.apellidoMaterno = null;
                    usrTmp.correo = null;
                    usrTmp.idUsuario = null;
                    usrTmp.nombre = null;
                    usrTmp.sistema = null;
                    usrTmp.uuid = null;
                    usrTmp.username = usrTmp.username.toLowerCase();
                    usrTmp.username = usrTmp.username.slice(3);

                    let result = await this.usuarioService.insertarUsuarioServicio(sistema.idSistema, usrTmp);

                    usrTmp.uuid = result.data.uuid;
                    let result2 = await this.permisosService.asociaPermiso(sistema.idSistema,
                        result.data.username, sistema.permiso.idPermisoRamo);
                    response.username = result.data.username;
                    response.secret = result.data.secret;
                  }
                  response.status = true;
                  return response;
                } catch (e) {
                  const status = e.status;
                  let errorMessage = '';
                  switch (status) {
                    case 400:
                      errorMessage = e.error.description;
                      break;
                    case 401:
                      errorMessage = Mensajes.NO_PERMITIDO;
                      break;
                    case 403:
                      errorMessage = Mensajes.PROHIBIDO;
                      break;
                    case 404:
                      errorMessage = Mensajes.NO_ENCONTRADO;
                      break;
                    case 406:
                      errorMessage = Mensajes.NO_ACEPTADO;
                      break;
                    case 409:
                      errorMessage = Mensajes.CONFLICTED;
                      break;
                    case 422:
                      errorMessage = Mensajes.INFO_INCORRECTA;
                      break;
                    default:
                      errorMessage = Mensajes.ERROR_SOLICITUD;
                      break;
                  }
                  response.status = false;
                  response.errorMessage = errorMessage;
                  return response;
                }
              }
            }
          }).then(result => {
            if (result.value.status) {
              swal({
                type: 'success',
                title: 'Listo',
                html: Mensajes.OPERACION_EXITOSA + '<br/>' +
                    '<br/>username: ' + result.value.username +
                    '<br/>secret: ' + result.value.secret
              }).then(() => {
                this.spinner = false;
                this.usuarioService.setNuevoUsuarioServicio(undefined);
                this.router.navigate(['/main-dashboard/']);
              });
            } else {
              this.spinner = false;
              swal({
                type: 'error',
                title: 'Error',
                text: result.value.errorMessage
              });
            }
          });
    }

    comparaSistemas(sisUsuario: Array<any>): Array<Sistema> {
        let tipoUsuario = this.usuarioService.getUsuario().tipUsuario.idTipoUsuario;
        let sistemasSci = [];
        if (tipoUsuario === 4) {
            sistemasSci = environment.sistemas.filter(item => item !== 'SCI_FRONT');
        } else {
            sistemasSci = environment.sistemas.filter(item => item === 'SCI_FRONT');
        }
        let sisTmp: Array<Sistema> = [];
        sistemasSci.filter(item => {
            sisUsuario.filter(itemu => {
                if (item === itemu.nombreCorto) {
                    sisTmp.push(itemu);
                }
            });
        });
        return sisTmp;
    }

    consultaFacultades(sistemaRol: SistemaRol) {
        this.spinner = true;
        this.permisosService.obtenerPermisosRol(sistemaRol.idSistema, sistemaRol.idRol)
            .subscribe(data => {
              sistemaRol.permiso = data.data[0];
              this.sistemaRoles.push(sistemaRol);
              this.spinner = false;
            });
    }

    activarUsuario(usuarioInactivo: Usuario) {
        if (!usuarioInactivo.estatus) {
            swal({
                text: '¿Activar Sistema ' + usuarioInactivo.sistema.descripcion + '?',
                type: 'question',
                confirmButtonText: 'Aceptar',
                cancelButtonText: 'Cancelar',
                showCancelButton: true,
                focusCancel: false,
                allowOutsideClick: false,
                showLoaderOnConfirm: true,
                preConfirm: (result) => {
                    if (result) {
                        this.spinner = true;
                        this.usuarioService.activarUsuario(usuarioInactivo)
                            .subscribe(
                                data => {
                                    swal({
                                        type: 'success',
                                        title: 'Listo',
                                        text: Mensajes.OPERACION_EXITOSA
                                    });
                                    this.spinner = false;
                                    return true;
                                },
                                error1 => {

                                    let texto;
                                    const status = error1.status;
                                    switch (status) {
                                        case 400:
                                            texto = error1.error.description;
                                            break;
                                        case 401:
                                            texto = Mensajes.NO_PERMITIDO;
                                            break;
                                        case 403:
                                            texto = Mensajes.PROHIBIDO;
                                            break;
                                        case 404:
                                            texto = Mensajes.NO_ENCONTRADO;
                                            break;
                                        case 406:
                                            texto = Mensajes.NO_ACEPTADO;
                                            break;
                                        default:
                                            console.log(error1);
                                            texto = Mensajes.ERROR_SOLICITUD;
                                            break;
                                    }
                                    this.spinner = false;
                                    swal({
                                        type: 'error',
                                        title: 'Error',
                                        text: texto
                                    });
                                }
                            );
                    }
                }
            }).then(
                result => {
                    if (result.dismiss !== undefined ) {
                        usuarioInactivo.estatus = !usuarioInactivo.estatus;
                    }
                }
            );
        } else {
            swal({
                text: '¿Desactivar Sistema ' + usuarioInactivo.sistema.descripcion + '?',
                type: 'question',
                confirmButtonText: 'Aceptar',
                cancelButtonText: 'Cancelar',
                showCancelButton: true,
                focusCancel: false,
                allowOutsideClick: false,
                showLoaderOnConfirm: true,
                preConfirm: (result) => {
                    if (result) {
                        this.spinner = true;
                        this.usuarioService.activarUsuario(usuarioInactivo)
                            .subscribe(
                                data => {
                                    swal({
                                        type: 'success',
                                        title: 'Listo',
                                        text: Mensajes.OPERACION_EXITOSA
                                    });
                                    this.spinner = false;
                                },
                                error1 => {

                                    let texto;
                                    const status = error1.status;
                                    switch (status) {
                                        case 400:
                                            texto = error1.error.description;
                                            break;
                                        case 401:
                                            texto = Mensajes.NO_PERMITIDO;
                                            break;
                                        case 403:
                                            texto = Mensajes.PROHIBIDO;
                                            break;
                                        case 404:
                                            texto = Mensajes.NO_ENCONTRADO;
                                            break;
                                        case 406:
                                            texto = Mensajes.NO_ACEPTADO;
                                            break;
                                        default:
                                            console.log(error1);
                                            texto = Mensajes.ERROR_SOLICITUD;
                                            break;
                                    }
                                    this.spinner = false;
                                    swal({
                                        type: 'error',
                                        title: 'Error',
                                        text: texto
                                    });
                                }
                            );
                    }
                }
            }).then(
                result => {
                    if (result.dismiss !== undefined ) {
                        usuarioInactivo.estatus = !usuarioInactivo.estatus;
                    }
                }
            );
        }
    }
}

