import {Component, OnInit} from '@angular/core';
import {environment} from '../../../environments/environment';
import { VERSION } from '../../../environments/version';

@Component({
  selector: 'app-pie-de-pagina',
  templateUrl: './pie-de-pagina.component.html',
  styleUrls: ['./pie-de-pagina.component.scss']
})
export class PieDePaginaComponent implements OnInit {
  year: number = new Date().getFullYear();
  version: string = VERSION.tag;

  constructor() {

  }

  ngOnInit() {
  }

}
