import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FacultadesComponent} from './facultades/facultades.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DataTablesModule} from 'angular-datatables';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {SweetAlert2Module} from '@toverux/ngx-sweetalert2';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ToastrModule} from 'ngx-toastr';
import {UsuarioService} from '../shared/services/operation/usuario.service';
import {UtilsService} from '../shared/services/operation/utils.service';
import {CredencialesService} from '../shared/services/operation/credenciales.service';
import {UiSwitchModule} from 'ngx-ui-switch';
import {FacultadesService} from '../shared/services/operation/facultades.services';
import {AdminUsuariosComponent} from './usuarios/usuarios.component';
import { SistemasComponent } from './sistemas/sistemas.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    DataTablesModule,
    NgbModule.forRoot(),
    SweetAlert2Module,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    UiSwitchModule
  ],
  declarations: [FacultadesComponent, AdminUsuariosComponent, SistemasComponent],
  providers: [
    UsuarioService,
    UtilsService,
    CredencialesService,
    FacultadesService]
})
export class AdministracionModule { }
