export class UserRol {
  cveRol: string;
  description: string;


  constructor(cveRol: string, description: string) {
    this.cveRol = cveRol;
    this.description = description;
  }
}
