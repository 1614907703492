import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {DataTablesModule} from 'angular-datatables';
import {RolesService} from '../shared/services/operation/roles.service';
import {ToastrService} from 'ngx-toastr';
import {CredencialesService} from '../shared/services/operation/credenciales.service';
import {UsuarioService} from '../shared/services/operation/usuario.service';
import {DatosUsuarioComponent} from './datos-usuario/datos-usuario.component';
import {FlujoAltaComponent} from './flujo-alta/flujo-alta.component';
import {ResumenUsuarioComponent} from './resumen-usuario/resumen-usuario.component';
import {RolesUsuarioComponent} from './roles-usuario/roles-usuario.component';
import {AltaUsuariosRoutingModule} from './alta-usuarios-routing.module';

import { DatosUsuarioServicioComponent } from './datos-usuario-servicio/datos-usuario-servicio.component';
import { FlujoAltaServicioComponent } from './flujo-alta-servicio/flujo-alta-servicio.component';
import { ResumenUsuarioServicioComponent } from './resumen-usuario-servicio/resumen-usuario-servicio.component';
import { RolesUsuarioServicioComponent } from './roles-usuario-servicio/roles-usuario-servicio.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    NgbModule.forRoot(),
    DataTablesModule,
    AltaUsuariosRoutingModule
  ],
  declarations: [
    DatosUsuarioComponent,
    FlujoAltaComponent,
    ResumenUsuarioComponent,
    RolesUsuarioComponent,
    DatosUsuarioServicioComponent,
    FlujoAltaServicioComponent,
    ResumenUsuarioServicioComponent,
    RolesUsuarioServicioComponent
  ],
  providers: [
    RolesService,
    ToastrService,
    CredencialesService,
    UsuarioService
  ]
})
export class AltaUsuariosModule { }
