import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Mensajes } from 'src/app/shared/interfaces/mensajes';
import { EntidadExt } from 'src/app/shared/model/entidad_ext';
import { Usuario } from 'src/app/shared/model/usuario';
import { UsuarioNuevo } from 'src/app/shared/model/UsuarioNuevo';
import { KeycloakService } from 'src/app/shared/services/keycloak/keycloak.service';
import { CredencialesService } from 'src/app/shared/services/operation/credenciales.service';
import { EntidadesService } from 'src/app/shared/services/operation/entidades.service';
import { UsuarioService } from 'src/app/shared/services/operation/usuario.service';
import { UtilsService } from 'src/app/shared/services/operation/utils.service';

@Component({
  selector: 'app-datos-usuario-servicio',
  templateUrl: './datos-usuario-servicio.component.html',
  styleUrls: ['./datos-usuario-servicio.component.css']
})
export class DatosUsuarioServicioComponent implements OnInit {

  entidades: Array<EntidadExt> = [];
  spinner = false;
  usuarioTmp: UsuarioNuevo;

  usuarioForm: FormGroup;
  // labels
  nombre: string = Mensajes.ETIQUETA_NOMBRE_SERVICIO;
  apaterno: string = Mensajes.ETIQUETA_APATERNO;
  amaterno: string = Mensajes.ETIQUETA_AMATERNO;
  correo: string = Mensajes.ETIQUETA_CORREO;
  entidad: string = Mensajes.ETIQUETA_ENTIDAD;
  estatus: string = Mensajes.ETIQUETA_ESTATUS;
  esEntidad: string = Mensajes.ETIQUETA_ES_ENTIDAD;
  tituloDatos: string = Mensajes.ETIQUETA_TITULO_DATOS;
  usernameRequerido: string = Mensajes.USERNAME_REQUERIDO;
  aPaternoRequerido: string = Mensajes.APATERNO_REQUERIDO;
  correoRequerido: string = Mensajes.CORREO_REQUERIDO;
  correoInvalido: string = Mensajes.CORREO_INVALIDO;

  constructor(public formBuilder: FormBuilder,
              private entidadesService: EntidadesService,
              private utils: UtilsService,
              private credencialService: CredencialesService,
              private usuarioService: UsuarioService) { }

  ngOnInit() {
    this.getEntidades();
    if (this.credencialService.validaLogin()) {
      this.usuarioTmp = this.usuarioService.getNuevoUsuarioServicio();
      if (this.usuarioTmp === undefined || this.usuarioTmp == null) {

        this.usuarioTmp = new UsuarioNuevo();
        this.usuarioForm = this.formBuilder.group({
          username:  new FormControl('', [Validators.required, Validators.pattern('^[A-Za-z0-9._-]{0,255}$')]),
          estatus:  new FormControl('', Validators.required),
          entidadExterna: new FormControl('', Validators.required),
        });

      } else {

          this.usuarioForm = this.formBuilder.group({
            username: new FormControl(this.usuarioTmp.usuario.username, Validators.required),
            estatus: new FormControl(this.usuarioTmp.usuario.estatus ? 'true' : 'false', Validators.required),
            entidadExterna: [this.usuarioTmp.usuario.entidadExterna
             !== undefined ? this.usuarioTmp.usuario.entidadExterna.idEntidadExterna : '']
          });
      }

    } else {
      KeycloakService.logout();
    }
  }

  getEntidades() {
    this.entidadesService.obtenerEntidades()
        .subscribe(data => {
              this.entidades = data.data;
              this.entidades = this.entidades.filter(entidad => entidad.estatus === true);
              this.spinner = false;
            }, error1 => {
              const status = error1.status;
              switch (status) {
                case 400:
                  this.utils.mostrarError(error1.error.description);
                  break;
                case 401:
                  this.utils.mostrarError(Mensajes.NO_PERMITIDO);
                  KeycloakService.logout();
                  break;
                case 403:
                  this.utils.mostrarError(Mensajes.PROHIBIDO);
                  break;
                case 404:
                  this.utils.mostrarError(Mensajes.NO_ENCONTRADO);
                  break;
                default:
                  console.log(error1);
                  this.utils.mostrarError(Mensajes.NO_ENTIDADES);
                  break;
              }
              this.spinner = false;
            }
        );
  }

  onSubmit() {
    if (this.usuarioForm.valid) {

      const newUsuario: Usuario = new Usuario();
      newUsuario.username = this.usuarioForm.get('username').value;
      newUsuario.entidadExterna = new EntidadExt(this.usuarioForm.get('entidadExterna').value);
      newUsuario.estatus = this.usuarioForm.get('estatus').value === 'true' ? true : false;

      const usuarioNuevo: UsuarioNuevo = new UsuarioNuevo();
      usuarioNuevo.usuario = newUsuario;
      if (this.usuarioTmp.sistemasRoles !== undefined) {
        usuarioNuevo.sistemasRoles = this.usuarioTmp.sistemasRoles;
      }
      this.usuarioService.setNuevoUsuarioServicio(usuarioNuevo);
      const element: HTMLElement = document.getElementById('roles-usuario-servicio') as HTMLElement;
      element.click();
    }
  }

}
