import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
// PLUGINS
import {InicioRoutingModule} from './inicio-routing.module';

// PAGES

@NgModule({
  imports: [
    CommonModule,
    InicioRoutingModule,
  ],
  declarations: [
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],

})
export class InicioModule { }
