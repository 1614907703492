import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {distinctUntilChanged, filter} from 'rxjs/operators';
import {Location} from '@angular/common';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html'
})
export class BreadcrumbComponent implements OnInit {
  breadcrumb = [];

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    private _location: Location) {

    this.router.events
    .pipe(filter(event => event instanceof NavigationEnd))
    .pipe(distinctUntilChanged())
    .subscribe(() =>  this.breadcrumb = this.getBreadcrumb(this.route.root));

  }

  ngOnInit() {}

  getBreadcrumb(route, breadcrumbs: any[] = []) {
    let breadCrumbs = breadcrumbs;
    let breadcrumb = route && route.routeConfig && route.routeConfig.data ? {label: route.routeConfig.data[ 'breadcrumb' ]} : {label: 'Entidades Externas' };

    if (route.routeConfig) { breadCrumbs.push(breadcrumb); }

    if (route.firstChild) { return(this.getBreadcrumb(route.firstChild, breadCrumbs)); }

    return breadCrumbs;

  }
}
