import { Usuario } from 'src/app/shared/model/usuario';
import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';

import {UsuarioNuevo} from '../../model/UsuarioNuevo';
import {Sistema} from '../../model/sistema';
import {Observable} from 'rxjs';
import {environment} from 'src/environments/environment';
import {AltaUsuarioResponse} from '../../model/alta-usuario-response';
import {AltaUsuarioServicioResponse} from '../../model/alta-usuario-servicio-response';


const endpointURL = environment.seguridadEndpoint;
const polizasVigentesURL = environment.polizasVigentes;
const ocraURL = environment.estatusVehiculoOCRA;
const perdidaTotalURL = environment.perdidaTotal;
const valuacionURL = environment.valuacion;

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json'
  }),
  withCredentials: false
};

@Injectable()
export class UsuarioService {

  usuario: Usuario;
  usuariosInactivos: Usuario[];
  private _nuevoUsuario: UsuarioNuevo;
  private _nuevoUsuarioServicio: UsuarioNuevo;

  constructor(private http: HttpClient) { }

  async obtenerUsuarios(sistema: number) {
    const endpoint = environment.seguridadEndpoint + '/sistemas/' + sistema + '/usuarios';
    return this.http.get<any>(endpoint).toPromise();
  }

  obtenerUsuario(sistema: number, username: string) {
    const endpoint = environment.seguridadEndpoint + '/sistemas/' + sistema + '/usuarios/' + username;
    return this.http.get<any>(endpoint);
  }

  async obtenerUsuariosServicio(sistema: number) {
    const endpoint = environment.seguridadEndpoint + '/sistemas/' + sistema + '/usuariosServicio';
    return this.http.get<any>(endpoint).toPromise();
  }

  getUsuario() {
    return this.usuario;
  }

  setUsuario(usuario: Usuario) {
    this.usuario = usuario;
  }

  getNuevoUsuario(): UsuarioNuevo {
    return this._nuevoUsuario;
  }

  setNuevoUsuario(value: UsuarioNuevo) {
    this._nuevoUsuario = value;
  }

  getNuevoUsuarioServicio(): UsuarioNuevo {
    return this._nuevoUsuarioServicio;
  }

  setNuevoUsuarioServicio(value: UsuarioNuevo) {
    this._nuevoUsuarioServicio = value;
  }

  getUsuariosInactivos(): Usuario[] {
    return this.usuariosInactivos;
  }

  setUsuariosInactivos(value: Usuario[]) {
    this.usuariosInactivos = value;
  }

  async insertarUsuario(sistema: number, usuario: Usuario): Promise<any> {
    let predicado = '';
    predicado = predicado.concat('/sistemas/');
    predicado = predicado.concat(sistema.toString());
    predicado = predicado.concat('/usuarios');
    const endpoint = endpointURL + predicado;

    usuario.estatus = true;
    return this.http.post<Usuario>(endpoint, usuario, httpOptions);
  }

  async insertarUsuarioAdmin(sistema: number, usuario: Usuario): Promise<AltaUsuarioResponse> {
      let predicado = '';
      predicado = predicado.concat('/sistemas/');
      predicado = predicado.concat(sistema.toString());
      predicado = predicado.concat('/usuarios');
      const endpoint = endpointURL + predicado;
      usuario.estatus = true;
      return this.http.post<AltaUsuarioResponse>(endpoint, usuario, httpOptions).toPromise();
  }

  eliminarUsuario(usuario: Usuario) {
    let predicado = '';
    predicado = predicado.concat('/sistemas/');
    predicado = predicado.concat(usuario.sistema.id.toString());
    predicado = predicado.concat('/usuarios/');
    predicado = predicado.concat(usuario.username);
    const endpoint = endpointURL + predicado;

    return this.http.delete<any>(endpoint);
  }

  activarUsuario(usuario: Usuario) {

    let predicado = '';
    predicado = predicado.concat('/sistemas/');
    predicado = predicado.concat(usuario.sistema.id.toString());
    predicado = predicado.concat('/usuarios/');
    const endpoint = endpointURL + predicado;

    return this.http.put<any>(endpoint, usuario);

  }

  actualizaUsuario(usuario: Usuario, idUsuario: number, sistema: number) {

    usuario.idUsuario = idUsuario;
    const nuevoSistema: Sistema = new Sistema();
    nuevoSistema.id = sistema;
    usuario.sistema = nuevoSistema;
    usuario.estatus = true;
    let predicado = '';
    predicado = predicado.concat('/sistemas/');
    predicado = predicado.concat(usuario.sistema.id.toString());
    predicado = predicado.concat('/usuarios/');
    const endpoint = endpointURL + predicado;
    console.log(usuario);
    return this.http.put<any>(endpoint, usuario);

  }

  async insertarUsuarioServicio(sistema: number, usuario: Usuario): Promise<AltaUsuarioServicioResponse> {
    let predicado = '';
    predicado = predicado.concat('/sistemas/');
    predicado = predicado.concat(sistema.toString());
    predicado = predicado.concat('/usuariosServicio');
    const endpoint = endpointURL + predicado;

    usuario.estatus = true;
    return this.http.post<AltaUsuarioServicioResponse>(endpoint, usuario, httpOptions).toPromise();
  }

  regenerarSecret(sistema: number, usuarioUUID: number): Observable<any> {
    let predicado = '';
    predicado = predicado.concat('/sistemas/');
    predicado = predicado.concat(sistema.toString());
    predicado = predicado.concat('/usuarios/');
    predicado = predicado.concat(usuarioUUID.toString());
    predicado = predicado.concat('/resetSecret');
    const endpoint = endpointURL + predicado;

    return this.http.post<any>(endpoint, null , httpOptions);
  }

  async limpiarCachePolizasVigentes(username: string): Promise<any>  {
      let predicado = '';
      predicado = predicado.concat('/cache/').concat(username);
      const endpoint = polizasVigentesURL + predicado;

      return this.http.delete<any>(endpoint, httpOptions).toPromise();
  }

  async limpiarCacheOCRA(username: string) {
    let predicado = '';
    predicado = predicado.concat('/cache/').concat(username);
    const endpoint = ocraURL + predicado;

    return this.http.delete<any>(endpoint, httpOptions).toPromise();
  }

  async limpiarCachePerdidaTotal(username: string) {
    let predicado = '';
    predicado = predicado.concat('/cache/').concat(username);
    const endpoint = perdidaTotalURL + predicado;

    return this.http.delete<any>(endpoint, httpOptions).toPromise();
  }

  async limpiarCacheValuaciones(username: string) {
    let predicado = '';
    predicado = predicado.concat('/cache/').concat(username);
    const endpoint = valuacionURL + predicado;

    return this.http.delete<any>(endpoint, httpOptions).toPromise();
  }

}
