export namespace Mensajes {

  // MENSAJES
  export const ERROR_CONEX_SERVICIO = 'No hay conexión con el servicio';
  export const NO_SISTEMAS = 'No cuenta con sistemas a administrar';
  export const NO_USUARIOS = 'No cuenta con usuarios a administrar';
  export const NO_ENTIDADES = 'No existen entidades externas para el usuario';
  export const NO_FACULTADES = 'No existen facultades para el usuario seleccionado';
  export const OPERACION_EXITOSA = '¡Operación exitosa!';
  export const INFO_INCORRECTA = 'La información ingresada es incorrecta';
  export const NO_PERMITIDO = 'Usuario no autorizado';
  export const PROHIBIDO = 'Usuario prohibido';
  export const NO_ENCONTRADO = 'Servicio no disponible';
  export const NO_ACEPTADO = 'Usuario no se encuentra registrado';
  export const CERRAR_SESION = '¿Desea cerrar su sesión?';
  export const CERRANDO_SESION = 'Tiempo de inactividad excedido, cerrando sesión';
  export const DATOS_INCOMPLETOS = 'Por favor ingrese información completa';
  export const SELECCIONAR_FACULTADES_AGREGAR = 'Seleccionar facultad a agregar';
  export const SELECCIONAR_FACULTADES_ELIMINAR = 'Seleccionar facultad a eliminar';
  export const ERROR_SOLICITUD = 'No se completó la solicitud';
  export const FACULTADES_EXITO_AGREGAR = '¡Facultades agregadas!';
  export const FACULTADES_EXITO_QUITAR = '¡Facultades eliminadas!';
  export const EDITAR_USUARIO_SIN_CAMBIOS = 'No se realizaron cambios al usuario';
  export const EDITAR_USUARIO_EXITO = 'Se ha activado el usuario';
  export const LIMPIAR_CACHE_USUARIO_EXITO = 'Se ha limpiado la cache del usuario';
  export const SISTEMA_REPETIDO = 'Sistema previamente insertado';
  export const SISTEMA_REPETIDO_DESACTIVADO = 'El usuario cuenta con el sistema ';
  export const SISTEMA_REPETIDO_DESACTIVADO_CONT = ' previamente desactivado';
  export const NOMBRE_REQUERIDO = 'Nombre es requerido.';
  export const USERNAME_REQUERIDO = 'Username es requerido.';
  export const APATERNO_REQUERIDO = 'Apellido paterno es requerido.';
  export const CORREO_REQUERIDO = 'Correo es requerido.';
  export const CORREO_INVALIDO = 'Correo es inválido.';
  export const CONFLICTED = 'Ya existe un usuario con esa cuenta de correo.';

  // ETIQUETAS
  export const ETIQUETA_BIENVENIDO = '¡Bienvenido!';
  export const ETIQUETA_CERRAR_SESION = 'Terminar sesión';
  export const ETIQUETA_SIGUIENTE = 'Siguiente';
  export const ETIQUETA_ANTERIOR = 'Anterior';
  export const ETIQUETA_CANCELAR = 'Cancelar';
  export const ETIQUETA_ACEPTAR = 'Aceptar';
  export const ETIQUETA_BUSCAR = 'Buscar';
  export const ETIQUETA_REGRESAR = 'Regresar';
  export const ETIQUETA_TABLAVACIA = 'Sin registros';
  export const ETIQUETA_DESCARGAR = 'Descargar';
  export const ETIQUETA_CONSULTA_BITACORA = 'Consulta de bitácora';
  export const BADGE_CONSULTA_BITACORA = 'Seleccionar rango de fechas';
  export const ETIQUETA_ADMIN_SISTEMAS = 'Administración de sistemas';
  export const BADGE_ADMIN_SISTEMAS = 'Seleccionar sistemas';
  export const ETIQUETA_CONSULTA_USUARIOS = 'Consulta de usuarios';
  export const ETIQUETA_CONSULTA_FACULTADES = 'Consulta de facultades';
  export const ETIQUETA_CONSULTA_BADGE = 'Datos del usuario';
  export const ETIQUETA_CONSULTA_BADGE_FACULTADES = 'Facultades en el sistema';
  export const ETIQUETA_CONSULTA_BADGE_COMPANIAS = 'Companias para la entidad externa';
  export const ETIQUETA_ADMIN_FACULTADES = 'Administración de facultades';
  export const ETIQUETA_ADMIN_BADGE = 'Datos del usuario';
  export const ETIQUETA_ADMIN_USUARIOS = 'Administración de usuarios';
  export const ETIQUETA_TITULO_DATOS = '1. Datos del usuario';
  export const ETIQUETA_NOMBRE = 'Nombre';
  export const ETIQUETA_NOMBRE_SERVICIO = 'Username';
  export const ETIQUETA_APATERNO = 'Apellido paterno';
  export const ETIQUETA_AMATERNO = 'Apellido materno';
  export const ETIQUETA_CORREO = 'Correo electrónico';
  export const ETIQUETA_ENTIDAD = 'Entidad externa';
  export const ETIQUETA_ESTATUS = 'Estatus';
  export const ETIQUETA_ES_ENTIDAD = '¿Es entidad externa?';
  export const ETIQUETA_TITULO_ROLES = '2. Roles del usuario';
  export const ETIQUETA_SISTEMA = 'Sistema';
  export const ETIQUETA_ROL = 'Rol';
  export const ETIQUETA_QUITAR = 'Quitar';
  export const ETIQUETA_TITULO_RESUMEN = '3. Resumen usuario';
  export const ETIQUETA_FACULTAD = 'Facultad';
  export const ETIQUETA_SISTEMA_ACTUAL = 'Sistemas actuales:';
}
