import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { HORA_FIN_DIA, HORA_INICIO_DIA, PATH_COUNT, PATH_FILE, PATH_PAGINATED } from '../../interfaces/constantes';

@Injectable()
export class BitacoraService {
  query: any = {};

  constructor(private http: HttpClient) {
  }

  obtenerBitacora (dateFrom: string, dateTo: string) {
    const from = this.incioDeDia(dateFrom);
    const to = this.finalDeDia(dateTo);
    this.query = { query: '{ bitacoraFecha(fechaDesde:\"' + from + '\",fechaHasta:\"' +
          to + '\") { id usuario vin servicio fechaConsulta operacion estatus} }' };
    return this.http.post<any>(environment.graphqlEndpoint, this.query);
  }

  obtenerBitacoraPaginado (dateFrom: string, dateTo: string, limit: number, offset: number) {
    const from = this.incioDeDia(dateFrom);
    const to = this.finalDeDia(dateTo);
    this.query = { query: '{ bitacoraFechaPaginado(fechaDesde:\"' + from + '\",fechaHasta:\"' +
          to + '\", limit:' + limit + ', offset: ' + offset + ' ) { id usuario vin servicio fechaConsulta operacion estatus } }' };
    return this.http.post<any>(environment.graphqlEndpoint + PATH_PAGINATED, this.query);
  }

  obtenerBitacoraCount (dateFrom: string, dateTo: string) {
    const from = this.incioDeDia(dateFrom);
    const to = this.finalDeDia(dateTo);
    this.query = { query: '{ countBitacoraFecha(fechaDesde:\"' + from + '\",fechaHasta:\"' + to + '\")}' };
    return this.http.post<any>(environment.graphqlEndpoint +  PATH_COUNT, this.query);
  }

  downloadBitacoraFile(dateFrom: string, dateTo: string) {
    const from = this.incioDeDia(dateFrom);
    const to = this.finalDeDia(dateTo);
    this.query = { query: '{bitacoraFecha(fechaDesde:"' + from + '",fechaHasta:"' + to + '"){id usuario vin servicio fechaConsulta operacion estatus}}' };
    return this.http.post(environment.graphqlEndpoint + PATH_FILE
    , this.query
    , {
        responseType: 'arraybuffer',
        observe: 'response' as 'body'
    });
}

  private finalDeDia (fecha: string): string {
    return fecha + ' ' + HORA_FIN_DIA;
  }

  private incioDeDia (fecha: string): string {
    return fecha + ' ' + HORA_INICIO_DIA;
  }

}
