import {Component, OnInit} from '@angular/core';
import {Location} from '@angular/common';
import {Mensajes} from '../../shared/interfaces/mensajes';
import {CredencialesService} from '../../shared/services/operation/credenciales.service';
import {UsuarioService} from '../../shared/services/operation/usuario.service';
import {PermisosService} from '../../shared/services/operation/permisos.service';
import {ActivatedRoute, Router} from '@angular/router';
import {UtilsService} from '../../shared/services/operation/utils.service';
import {Facultad} from '../../shared/model/facultad';
import {Permiso} from '../../shared/model/permiso';
import {Usuario} from '../../shared/model/usuario';
import {Credencial} from '../../shared/model/credencial';
import {environment} from '../../../environments/environment';
import {KeycloakService} from '../../shared/services/keycloak/keycloak.service';
import * as configuracion from '../../shared/services/configurations';
import {EntidadesService} from '../../shared/services/operation/entidades.service';

@Component({
  selector: 'app-facultades',
  templateUrl: './facultades.component.html'
})
export class ConsultaFacultadesComponent implements OnInit {

  header: string;
  badge: string;
  badgeFacultades: string;

  constructor(private credencialService: CredencialesService,
              private usuarioService: UsuarioService,
              private permisosService: PermisosService,
              private entidadesService: EntidadesService,
              private router: Router,
              private route: ActivatedRoute,
              private _location: Location,
              private utils: UtilsService) {

    this.header = Mensajes.ETIQUETA_CONSULTA_FACULTADES;
    this.badge = Mensajes.ETIQUETA_CONSULTA_BADGE;
    this.facultades = configuracion.FACULTADES;
  }

  facs: Array<Facultad> = [];
  permiso: Permiso;
  usuario: Usuario = new Usuario();
  usuarioLogeado: Credencial;
  resultados = false;
  nombreCompleto = '';
  nombreSistema = '';
  idValuacion = environment.idvaluacion;
  facultades: any[] = [];
  cias: any[] = [];
  acciones = false;
  spinner = false;
  valuacion = false;

  // opciones tabla
  optionsDataTable: any = {
    select: false,
    paging: false,
    info: false,
    order: [[0, 'asc']],
    ordering: true,
    lengthChange: false,
    scrollY: '50vh',
    scrollCollapse: true,
    pageLength: 15,
    language: {
      search: Mensajes.ETIQUETA_BUSCAR,
      emptyTable: Mensajes.ETIQUETA_TABLAVACIA,
      paginate: {
        next: Mensajes.ETIQUETA_SIGUIENTE,
        previous: Mensajes.ETIQUETA_ANTERIOR,
      }
    }

  };

  ngOnInit() {

    if (this.credencialService.validaLogin()) {
      this.spinner = true;
      this.usuarioLogeado = this.credencialService.getCredencial();
      this.usuario = this.usuarioService.getUsuario();
      this.nombreCompleto = this.usuario.nombre + ' ' + this.usuario.apellidoPaterno + ' '
          + this.usuario.apellidoMaterno;
      this.nombreSistema = this.usuario.sistema.descripcion;

      if (this.usuario.sistema.id !== this.idValuacion) {
        this.permisosService.obtenerPermisos(this.usuario.username, this.usuario.sistema.id)
            .subscribe(
                data => {
                  this.permiso = data.data[0];
                  if (this.permiso != null || this.permiso !== undefined) {
                    if (this.permiso.estatus && this.permiso.facultades.length > 0) {
                      this.facs = this.permiso.facultades;
                      this.badgeFacultades = Mensajes.ETIQUETA_CONSULTA_BADGE_FACULTADES + ' ' + this.usuario.sistema.descripcion;
                      this.resultados = true;
                    } else {
                      this.utils.mostrarAviso(Mensajes.NO_FACULTADES);
                    }
                  } else {
                    this.utils.mostrarAviso(Mensajes.NO_FACULTADES);
                  }
                  this.spinner = false;
                },
                error1 => {
                  const status = error1.status;
                  switch (status) {
                    case 400:
                      this.utils.mostrarError(error1.error.description);
                      break;
                    case 401:
                      this.utils.mostrarError(Mensajes.NO_PERMITIDO);
                      KeycloakService.logout();
                      break;
                    case 403:
                      this.utils.mostrarError(Mensajes.PROHIBIDO);
                      break;
                    case 404:
                      this.utils.mostrarError(Mensajes.NO_ENCONTRADO);
                      break;
                    default:
                      console.log(error1);
                      this.utils.mostrarError(Mensajes.NO_ENCONTRADO);
                      break;
                  }
                  this.spinner = false;
                }
            );
        this.acciones = this.consultaFacultades();
      } else {
        if (this.usuario.entidadExterna != null) {
          this.entidadesService.obtenerCompanias(this.usuario.entidadExterna.idEntidadExterna)
              .subscribe(data => {
                  data.data.forEach( (ca) => {
                    if (ca.estatus) {
                      this.cias.push(ca);
                    }
                  });
                  this.spinner = false;
                  this.valuacion = true;
                this.badgeFacultades = Mensajes.ETIQUETA_CONSULTA_BADGE_COMPANIAS + ' ' + this.usuario.entidadExterna.nombre;
              }, error1 => {
                const status = error1.status;
                switch (status) {
                  case 400:
                    this.utils.mostrarError(error1.error.description);
                    break;
                  case 401:
                    this.utils.mostrarError(Mensajes.NO_PERMITIDO);
                    KeycloakService.logout();
                    break;
                  case 403:
                    this.utils.mostrarError(Mensajes.PROHIBIDO);
                    break;
                  case 404:
                    this.utils.mostrarError(Mensajes.NO_ENCONTRADO);
                    break;
                  default:
                    console.log(error1);
                    this.utils.mostrarError(Mensajes.NO_ENCONTRADO);
                    break;
                }
                this.spinner = false;
              });
        } else {
          this.spinner = false;
          this.utils.mostrarAviso(Mensajes.NO_ENTIDADES);
        }
      }
    } else {
      this.spinner = false;
      KeycloakService.logout();
    }
  }

  regresar() {
    this.usuarioService.setUsuario(null);
    this._location.back();
  }

  editarFacultades() {
    this.usuario.facultades = this.facs;
    this.usuarioService.setUsuario(this.usuario);
    this.router.navigate(['/main-dashboard/admin-facultades']);
  }

  consultaFacultades(): boolean {
    const acciones: any[] = [];
    for (const fac of this.facultades) {
      if (fac.nombre === 'editar') {
        for (const rol of fac.permisosRol) {
          this.usuarioLogeado.facultades.forEach(facultad => {
            if (rol === facultad.nombre) {
              return true;
            }
          });
        }
      }
    }
    return false;
  }
}
