import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {Daterangepicker} from 'ng2-daterangepicker';
import {DataTablesModule} from 'angular-datatables';
import {SweetAlert2Module} from '@toverux/ngx-sweetalert2';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ToastrModule} from 'ngx-toastr';

import {UsuariosComponent} from './usuarios/usuarios.component';
import {BitacoraComponent} from './bitacora/bitacora.component';

import {BitacoraService} from '../shared/services/operation/bitacora.services';
import {UsuarioService} from '../shared/services/operation/usuario.service';
import {UtilsService} from '../shared/services/operation/utils.service';
import {CredencialesService} from '../shared/services/operation/credenciales.service';
import {ConsultaFacultadesComponent} from './facultades/facultades.component';


@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    DataTablesModule,
    NgbModule.forRoot(),
    Daterangepicker,
    SweetAlert2Module,
    BrowserAnimationsModule,
    ToastrModule.forRoot()
  ],
  declarations: [UsuariosComponent, BitacoraComponent, ConsultaFacultadesComponent],
  providers: [
      BitacoraService,
      UsuarioService,
      UtilsService,
      CredencialesService]
})
export class ConsultaModule { }
