export class Facultad {
  idFacultad: number;
  nombre: string;
  descripcion: string;
  estatus: boolean;

  constructor(idFacultad: number) {
    this.idFacultad = idFacultad;
  }
}
