export class Sistema {

  id: number;
  descripcion: string;
  nombreCorto: string;
  estatus: boolean;
  url: string;
  secret: string;


  constructor() {}

}
