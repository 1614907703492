export const PAGE_SIZE: number = 25;
export const DATE_FORMAT:string = 'YYYY-MM-DD';
export const HORA_INICIO_DIA:string = '00:00:00';
export const HORA_FIN_DIA:string = '23:59:59';

export const PATH_COUNT:string = '/count';
export const PATH_PAGINATED:string = '/paginated';
export const PATH_FILE:string = '/file';

export const XLSX_CONTENT_TYPE:string = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
export const NOMBRE_ARCHIVO_BITACORA:string = `Entidades Externas.xlsx`;
export const ERROR:string = 'error';