import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';

import {Mensajes} from '../../shared/interfaces/mensajes';
import {environment} from '../../../environments/environment';
import {Credencial} from '../../shared/model/credencial';
import {Usuario} from '../../shared/model/usuario';
import {Sistema} from '../../shared/model/sistema';

import {UsuarioService} from '../../shared/services/operation/usuario.service';
import {UtilsService} from '../../shared/services/operation/utils.service';
import {CredencialesService} from '../../shared/services/operation/credenciales.service';
import {KeycloakService} from '../../shared/services/keycloak/keycloak.service';

@Component({
    selector: 'app-usuarios',
    templateUrl: './usuarios.component.html'
})
export class AdminUsuariosComponent implements OnInit {

    //atributtes
    usuarioLogeado: Credencial;
    usuDisponibles: Array<Usuario> = [];
    finalUsuarios: Array<Usuario> = [];
    sistemasIncluidos = "";
    spinner = false;
    resultados = false;
    tieneValuacion = false;

    // labels
    header: string;

    // opciones tabla
    optionsDataTable: any = {
        dom: 'Bfrtip',
        buttons: [
            {extend: 'excel', text: Mensajes.ETIQUETA_DESCARGAR, className: 'btn btn-primary'}
        ],
        select: false,
        paging: true,
        info: false,
        order: [[0, 'asc']],
        ordering: true,
        lengthChange: false,
        scrollY: '50vh',
        scrollCollapse: true,
        pageLength: 15,
        language: {
            search: Mensajes.ETIQUETA_BUSCAR,
            emptyTable: Mensajes.ETIQUETA_TABLAVACIA,
            paginate: {
                next: Mensajes.ETIQUETA_SIGUIENTE,
                previous: Mensajes.ETIQUETA_ANTERIOR,
            }
        }

    };

    constructor(private usuarioService: UsuarioService,
                private utils: UtilsService,
                private credencialService: CredencialesService,
                private router: Router) {

        this.header = Mensajes.ETIQUETA_ADMIN_USUARIOS;
    }

    ngOnInit() {
        if (this.credencialService.validaLogin()) {
            this.spinner = true;
            this.consultaUsuarios();
        } else {
            KeycloakService.logout();
        }
    }

    async consultaUsuarios() {

        const sistemasLogeados = await this.comparaSistemas(this.credencialService.getCredencial().sistemas);
        for (const sis of sistemasLogeados) {
            this.sistemasIncluidos += sis.descripcion + ', ';
            let res = await this.usuarioService.obtenerUsuarios(sis.id);
            if (res != null || res !== undefined) {
                this.concatenaArreglo(res.data, sis, sistemasLogeados.length);
            }
            let resServicio = await this.usuarioService.obtenerUsuariosServicio(sis.id);
            if (resServicio != null || resServicio !== undefined) {
                this.concatenaArreglo(resServicio.data, sis, sistemasLogeados.length);
            }
        }
        this.manejarDatos();
    }

    concatenaArreglo(usuarios: Array<Usuario>, sistema: Sistema, indiceDetener: number) {
        if (this.tieneValuacion) {
            indiceDetener = indiceDetener - 1;
        }

        for (const usuario of usuarios) {
            usuario.sistema = sistema;
            if (usuario.sistema.descripcion === 'SCI UI ADMIN'){
                this.usuDisponibles.push(usuario);
            } else if (usuario.username.includes('SU_')) {
                this.usuDisponibles.push(usuario);
            }
        }
    }

    manejarDatos() {
        if (this.usuDisponibles.length > 0) {
            this.finalUsuarios = this.usuDisponibles;
            this.spinner = false;
            this.resultados = true;
        } else {
            this.utils.mostrarAviso(Mensajes.NO_USUARIOS);
            this.router.navigate(['/main-dashboard']);
        }
    }

    editarUsuario(usuario: Usuario) {
        this.usuarioService.setUsuario(usuario);
        this.usuarioService.setUsuariosInactivos(
            this.finalUsuarios.filter(I => I.username === usuario.username && I.estatus === false)
        );
        this.router.navigate(['/main-dashboard/admin-facultades']);
    }

    /**
     * filtra unicamente los sistemas configurados para SCI que son los que el usuario puede operar desde la UI
     *
     * @param sisLogeado array con los sistemas en los que el usuario en sesión tiene permisos de operar
     */
    comparaSistemas(sisLogeado: Array<any>): Array<any> {
        let sistemasSci = environment.sistemas;
        let sisTmp: Array<Sistema> = [];
        sistemasSci.filter(item => {
            sisLogeado.filter(itemu => {
                if (item === itemu.nombreCorto) {
                    sisTmp.push(itemu);
                }
            });
        });
        return sisTmp;
    }

}
