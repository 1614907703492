import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Facultad} from '../../model/facultad';
import { environment } from 'src/environments/environment';


const endpointURL = environment.seguridadEndpoint;

@Injectable()
export class FacultadesService {
  constructor(private http: HttpClient) { }

  obtenerFacultadesSistema(sistema: number) {
    let predicado = '';
    predicado = predicado.concat('/sistemas/');
    predicado = predicado.concat(sistema.toString());
    predicado = predicado.concat('/facultades/');
    const endpoint = endpointURL + predicado;
    return this
            .http
            .get<any>(endpoint);
  }

  guardaFacultad(sistema: number, usuario: string, permiso: number, facultades: Array<Facultad>) {

    let predicado = '';
    predicado = predicado.concat('/sistemas/');
    predicado = predicado.concat(sistema.toString());
    predicado = predicado.concat('/usuarios/');
    predicado = predicado.concat(usuario);
    predicado = predicado.concat('/permisos/');
    predicado = predicado.concat(permiso.toString());
    predicado = predicado.concat('/facultades');
    const endpoint = endpointURL + predicado;

    return this
            .http
            .post(endpoint, facultades);
  }

  borraFacultad(sistema: number, usuario: string, permiso: number, facultades: Array<Facultad>) {
    let predicado = '';
    predicado = predicado.concat('/sistemas/');
    predicado = predicado.concat(sistema.toString());
    predicado = predicado.concat('/usuarios/');
    predicado = predicado.concat(usuario);
    predicado = predicado.concat('/permisos/');
    predicado = predicado.concat(permiso.toString());
    predicado = predicado.concat('/facultades');
    const endpoint = endpointURL + predicado;

    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }), body: facultades
    };

    return this
        .http
        .delete(endpoint, httpOptions);
  }



}

