import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import * as $ from 'jquery/dist/jquery';
// interfaces
import {FsDocument} from 'src/app/shared/interfaces/fs-document';
import {FsDocumentElement} from 'src/app/shared/interfaces/fs-document-element';
import swal from 'sweetalert2';
import { KeycloakService } from 'src/app/shared/services/keycloak/keycloak.service';
import { Mensajes } from 'src/app/shared/interfaces/mensajes';

@Component({
  selector: 'app-barra-superior',
  templateUrl: './barra-superior.component.html',
  styleUrls: ['./barra-superior.component.scss']
})
export class BarraSuperiorComponent implements OnInit {
  @Output() clickButtonMenu = new EventEmitter<boolean>();

  public nombreUsuario = '';
  public bienvenido;
  public terminarSesion;

  constructor() {
    this.bienvenido = Mensajes.ETIQUETA_BIENVENIDO;
    this.terminarSesion = Mensajes.ETIQUETA_CERRAR_SESION;
  }

  ngOnInit() {
    this.nombreUsuario = KeycloakService.getFullName().replace('+', ' ');
  }

  clickBtnMenu() {
    $('body').toggleClass('enlarged');
    this.clickButtonMenu.emit($('body').hasClass('enlarged'));
  }

  isFullScreen(): boolean {
    const fsDoc = <FsDocument> <unknown>document;

    return !!(fsDoc.fullscreenElement
      || fsDoc.mozFullScreenElement
      || fsDoc.webkitFullscreenElement
      || fsDoc.msFullscreenElement);
  }

  toggleFullScreen(): void {
    let fsDoc = <FsDocument> <unknown>document;

    if (!this.isFullScreen()) {
      let fsDocElem = <FsDocumentElement> document.documentElement;

      if (fsDocElem.requestFullscreen) {
        fsDocElem.requestFullscreen();
      } else if (fsDocElem.msRequestFullscreen) {
        fsDocElem.msRequestFullscreen();
      } else if (fsDocElem.mozRequestFullScreen) {
        fsDocElem.mozRequestFullScreen();
      } else if (fsDocElem.webkitRequestFullscreen) {
        fsDocElem.webkitRequestFullscreen();
      }
    } else if (fsDoc.exitFullscreen) {
      fsDoc.exitFullscreen();
    } else if (fsDoc.msExitFullscreen) {
      fsDoc.msExitFullscreen();
    } else if (fsDoc.mozCancelFullScreen) {
      fsDoc.mozCancelFullScreen();
    } else if (fsDoc.webkitExitFullscreen) {
      fsDoc.webkitExitFullscreen();
    }
  }

  cerrarSesion() {
    swal({
      text: Mensajes.CERRAR_SESION,
      type: 'question',
      confirmButtonText: 'Aceptar',
      showCancelButton: true,
      focusCancel: false,
      allowOutsideClick: false,
      showLoaderOnConfirm: true,
      preConfirm: (result) => {
        if (result) {
          KeycloakService.logout();
        }
      }
    });
  }
}
