import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import { KeycloakService } from './app/shared/services/keycloak/keycloak.service';
import {environment} from './environments/environment';

if (environment.production) {
  enableProdMode();
}

KeycloakService.init().then(
    () => {
      platformBrowserDynamic().bootstrapModule(AppModule)
          .catch(err => console.log(err));
    }
)