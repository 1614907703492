import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

import {Mensajes} from '../../shared/interfaces/mensajes';
import {Bitacora} from '../../shared/interfaces/bitacora';

import {BitacoraService} from '../../shared/services/operation/bitacora.services';
import {UtilsService} from '../../shared/services/operation/utils.service';
import { DATE_FORMAT, NOMBRE_ARCHIVO_BITACORA, PAGE_SIZE, XLSX_CONTENT_TYPE } from 'src/app/shared/interfaces/constantes';

@Component({
  selector: 'app-bitacora',
  templateUrl: './bitacora.component.html'
})


export class BitacoraComponent implements OnInit {

  bitacoraForm: FormGroup;
  bitacoraFormFile: FormGroup;
  consulta = false;
  daterange: any = {};
  resultados: Array<Bitacora> = [];
  spinner = false;
  totalRegistros:number;
  totalPaginas:number;
  from:string;
  to:string;
  pageSize:number = PAGE_SIZE;
  page:number;

  // labels
  header: string;
  badge: string;
  inputs: any;


  optionsDateRange: any = {
    lbuttonClasses: ['btn', 'btn-info', 'waves-light'],
    applyClass: 'btn-custom',
    cancelClass: 'btn-light',
    autoUpdateInput: false,
    locale: {
      cancelLabel: Mensajes.ETIQUETA_CANCELAR,
      applyLabel: Mensajes.ETIQUETA_ACEPTAR,
      format: DATE_FORMAT
    }
  };

  optionsDataTable: any = {
    dom: 'Bfrtip',
    buttons: [],
    select: false,
    paging: false,
    info: false,
    ordering: false,
    lengthChange: false,
    scrollY: '50vh',
    scrollCollapse: true,
    pageLength: this.pageSize,
    language: {
      search: Mensajes.ETIQUETA_BUSCAR,
      emptyTable: Mensajes.ETIQUETA_TABLAVACIA,
      paginate: {
        next: Mensajes.ETIQUETA_SIGUIENTE,
        previous: Mensajes.ETIQUETA_ANTERIOR,
      }
    }

  };

  constructor(public formBuilder: FormBuilder,
              private bitacoraService: BitacoraService,
              private utils: UtilsService) {

    this.bitacoraForm = formBuilder.group({
      rangofechas:     ['', Validators.required]
    });

    this.bitacoraFormFile = formBuilder.group({
      rangofechas:     ['', Validators.required]
    });

  }

  ngOnInit() {
    this.header = Mensajes.ETIQUETA_CONSULTA_BITACORA;
    this.badge = Mensajes.BADGE_CONSULTA_BITACORA;
    this.inputs = ['Rango de Fechas', Mensajes.ETIQUETA_BUSCAR, Mensajes.ETIQUETA_REGRESAR];
  }

  selectedDate(value: any, datepicker?: any) {
    datepicker.start = value.start;
    datepicker.end = value.end;
    this.bitacoraForm.get('rangofechas')
        .setValue(datepicker.start.format(DATE_FORMAT) + ' - ' +datepicker.end.format(DATE_FORMAT));
  }

  onSubmit(){

    this.from = this.daterange.start.format(DATE_FORMAT);
    this.to = this.daterange.end.format(DATE_FORMAT);
    this.procesaPaginado(this.from, this.to);
    this.obtieneDatosBitacora(this.from, this.to, 0);

  } 

  onSubmitFile(){
    this.spinner = true;
    this.bitacoraService.downloadBitacoraFile(this.from, this.to)
    .subscribe((data:any)=>{
      let blob = new Blob([data.body], {type: XLSX_CONTENT_TYPE});
      this.downloadBlob(NOMBRE_ARCHIVO_BITACORA, blob);
     this.spinner = false;
    });
  }

  downloadBlob(fileName: string, blob: Blob): void {
    const anchor = window.document.createElement('a');
    anchor.href = window.URL.createObjectURL(blob);
    anchor.download = fileName;
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
    window.URL.revokeObjectURL(anchor.href);
  }

  regresar() {
    this.consulta = false;
    this.spinner = false;
    this.badge = Mensajes.BADGE_CONSULTA_BITACORA;
    this.resultados = [];
  }

  procesaPaginado(from: string, to: string){
    this.bitacoraService.obtenerBitacoraCount(from, to)
    .subscribe(data=>{
      this.totalRegistros = data.count;
      this.totalPaginas = this.obtieneCantidadPaginas(this.totalRegistros, PAGE_SIZE);
    });
  }

  obtieneDatosBitacora(from: string, to: string, limite:number){
    this.spinner = true;
    this.bitacoraService.obtenerBitacoraPaginado(from, to, PAGE_SIZE, limite)
    .subscribe(data => {
          this.resultados = data.data.bitacoraFechaPaginado;
          this.consulta = true;
          this.spinner = false;
          this.badge = 'Resultados de ' + from + ' a ' + to;
    },
        error1 => {
          console.log('error');
          console.log(error1);
          this.consulta = false;
          this.spinner = false;
          this.utils.mostrarError(error1.message);
        }
    );
  }

  obtieneCantidadPaginas(registros:number, cantPorPagina:number){
    
    let resultado:number = 0;
    if(cantPorPagina > 0){
      resultado = Math.ceil(registros / cantPorPagina);
    }
    
    return resultado;
  }

  clickPageTable(page) {
    this.obtieneDatosBitacora(this.from, this.to, page -1);
}
  
}
