import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Mensajes } from 'src/app/shared/interfaces/mensajes';
import { Rol } from 'src/app/shared/model/rol';
import { SistemaRol } from 'src/app/shared/model/sistemaRol';
import { TipoUsuario } from 'src/app/shared/model/tipoUsuario';
import { UsuarioNuevo } from 'src/app/shared/model/UsuarioNuevo';
import { KeycloakService } from 'src/app/shared/services/keycloak/keycloak.service';
import { CredencialesService } from 'src/app/shared/services/operation/credenciales.service';
import { PermisosService } from 'src/app/shared/services/operation/permisos.service';
import { RolesService } from 'src/app/shared/services/operation/roles.service';
import { UsuarioService } from 'src/app/shared/services/operation/usuario.service';
import { UtilsService } from 'src/app/shared/services/operation/utils.service';
import { environment } from 'src/environments/environment';
import swal from 'sweetalert2';
import {AltaUsuarioResponse} from "../../shared/model/alta-usuario-response";
import {UsuarioServicioWrapper} from "../../shared/model/usuario-servicio-wrapper";

@Component({
  selector: 'app-resumen-usuario-servicio',
  templateUrl: './resumen-usuario-servicio.component.html',
  styleUrls: ['./resumen-usuario-servicio.component.css']
})
export class ResumenUsuarioServicioComponent implements OnInit {

  usuarioNuevo: UsuarioNuevo;
  sistemasTmp: Array<SistemaRol> = [];
  datosUsuario = false;
  datosRoles = false;
  spinner = false;
  cont = 0;

  optionsDataTable: any = {
    select: false,
    paging: false,
    info: false,
    sortable: false,
    ordering: true,
    searching: false,
    scrollY: '50vh',
    scrollCollapse: true,
    language: {
      search: Mensajes.ETIQUETA_BUSCAR,
      emptyTable: Mensajes.ETIQUETA_TABLAVACIA
    }
  };

  // labels
  tituloResumen: string = Mensajes.ETIQUETA_TITULO_RESUMEN;
  nombre: string = Mensajes.ETIQUETA_NOMBRE;
  correo: string = Mensajes.ETIQUETA_CORREO;
  lblsistema: string = Mensajes.ETIQUETA_SISTEMA;
  lblfacsSistema: string = Mensajes.ETIQUETA_CONSULTA_BADGE_FACULTADES;
  lblfacultad: string = Mensajes.ETIQUETA_FACULTAD;

  constructor(private usuarioService: UsuarioService,
              private credencialService: CredencialesService,
              private permisosService: PermisosService,
              private rolService: RolesService,
              private toastr: ToastrService,
              private utils: UtilsService,
              private router: Router) { }

  ngOnInit() {
    if (this.credencialService.validaLogin()) {
      this.usuarioNuevo = this.usuarioService.getNuevoUsuarioServicio();
      this.usuarioNuevo.usuario.username = this.usuarioNuevo.usuario.username.toUpperCase();
      if (this.usuarioNuevo !== undefined) {
        this.datosUsuario = true;
        this.consultaFacultades(this.usuarioNuevo.sistemasRoles);
      } else {
        const element: HTMLElement = document.getElementById('datos-usuario-servicio') as HTMLElement;
        element.click();
        this.toastr.error(Mensajes.DATOS_INCOMPLETOS, 'Error!!!');
      }
    } else {
      KeycloakService.logout();
    }
  }

  consultaFacultades(sistemasRoles: Array<SistemaRol>) {
    this.spinner = true;
    for (const sistema of sistemasRoles) {
      this.permisosService.obtenerPermisosRol(sistema.idSistema, sistema.idRol)
          .subscribe(data => {
            sistema.permiso = data.data[0];
            this.sistemasTmp.push(sistema);
            this.cont++;
            this.validaDatos();
          });
    }

  }

  validaDatos() {
    if (this.cont === this.usuarioNuevo.sistemasRoles.length) {
      if (this.sistemasTmp.length > 0) {
        this.datosRoles = true;
        this.usuarioNuevo.sistemasRoles = this.sistemasTmp;
      } else {
        this.toastr.info(Mensajes.NO_FACULTADES);
      }
      this.spinner = false;
    }
  }

  guardarUsuario() {

    swal({
      text: '¿Guardar Usuario?',
      type: 'question',
      confirmButtonText: 'Aceptar',
      showCancelButton: true,
      focusCancel: false,
      allowOutsideClick: false,
      showLoaderOnConfirm: true,
      preConfirm: async (result): Promise<UsuarioServicioWrapper> => {
        if (result) {
          this.spinner = true;
          let response = new UsuarioServicioWrapper();
          try {
            for (const sistema of this.usuarioNuevo.sistemasRoles) {
              const usrTmp = JSON.parse(JSON.stringify(this.usuarioNuevo.usuario));
              usrTmp.rol = JSON.parse(JSON.stringify(new Rol(sistema.idRol)));
              usrTmp.tipUsuario = JSON.parse(JSON.stringify(new TipoUsuario(environment.tipoUSuarioSWS)));
              let result = await this.usuarioService.insertarUsuarioServicio(sistema.idSistema, usrTmp);

              usrTmp.uuid = result.data.uuid;
              let result2 = await this.permisosService.asociaPermiso(sistema.idSistema, result.data.username, sistema.permiso.idPermisoRamo);
              response.username = result.data.username;
              response.secret = result.data.secret;
            }
            response.status = true;
            return response;
          } catch (e) {
            const status = e.status;
            let errorMessage = '';
            switch (status) {
              case 400:
                errorMessage = e.error.description;
                break;
              case 401:
                errorMessage = Mensajes.NO_PERMITIDO;
                break;
              case 403:
                errorMessage = Mensajes.PROHIBIDO;
                break;
              case 404:
                errorMessage = Mensajes.NO_ENCONTRADO;
                break;
              case 406:
                errorMessage = Mensajes.NO_ACEPTADO;
                break;
              case 422:
                errorMessage = Mensajes.INFO_INCORRECTA;
                break;
              default:
                console.log(e);
                errorMessage = Mensajes.ERROR_SOLICITUD;
                break;
            }
            response.status = false;
            response.errorMessage = errorMessage;
            return response;
          }
        }
      }
    }).then(result => {
      console.log(result);
      if (result.value.status) {
        swal({
          type: 'success',
          title: 'Listo',
          html: Mensajes.OPERACION_EXITOSA + '<br/>' +
              '<br/>username: ' + result.value.username +
              '<br/>secret: ' + result.value.secret
        }).then(() => {
          this.spinner = false;
          this.usuarioNuevo = null;
          this.sistemasTmp = [];
          this.datosUsuario = false;
          this.datosRoles = false;
          this.cont = 0;
          this.usuarioService.setNuevoUsuarioServicio(undefined);
          this.router.navigate(['/main-dashboard/']);
        });
      } else {
        this.spinner = false;
        swal({
          type: 'error',
          title: 'Error',
          text: result.value.errorMessage
        });
      }
    });
  }

  cancelar() {
    const element: HTMLElement = document.getElementById('roles-usuario-servicio') as HTMLElement;
    element.click();
  }

}
