export class EntidadExt {

  idEntidadExterna: number;
  nombre: string;
  estatus: boolean;

  constructor(id: number) {
    this.idEntidadExterna = id;
  }

}
