import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { environment } from 'src/environments/environment';


const endpointURL = environment.seguridadEndpoint;

@Injectable()
export class RolesService {

  constructor(private http: HttpClient) { }

  obtenerRoles(sistema:number){
    let predicado = '';
    predicado = predicado.concat('/sistemas/');
    predicado = predicado.concat(sistema.toString());
    predicado = predicado.concat('/roles/');
    const endpoint = endpointURL + predicado;

    return this.http.get<any>(endpoint);
  }

}
