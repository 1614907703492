import {EntidadExt} from './entidad_ext';
import {Sistema} from './sistema';
import {Facultad} from './facultad';
import {Rol} from './rol';
import {TipoUsuario} from './tipoUsuario';

export class Usuario {

  idUsuario: number;
  username: string;
  nombre: string;
  uuid: string;
  apellidoPaterno: string;
  apellidoMaterno: string;
  correo: string;
  estatus: boolean;
  entidadExterna: EntidadExt;
  sistema?: Sistema;
  facultades?: Array<Facultad>;
  rol: Rol;
  tipUsuario: TipoUsuario;

    constructor() { }

}
