import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest,} from '@angular/common/http';
import {Observable} from 'rxjs';
import {mergeMap, tap} from 'rxjs/operators';
import {KeycloakService} from '../keycloak/keycloak.service';


@Injectable()
export class InterceptorProvider implements HttpInterceptor {

    constructor(private _keycloakService: KeycloakService) {
    }

    addToken(req: HttpRequest<any>): HttpRequest<any> {
        return req.clone({setHeaders: {Authorization: 'Bearer ' + this._keycloakService.getToken()}});
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return this._keycloakService.refreshToken().pipe(mergeMap(() => {
            return next.handle(this.addToken(req)).pipe(tap(result => {
            }, e => {
                console.log('ERROR  INTERCEPTOR', e);
            }));
        }));
    }
}
