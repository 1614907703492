import { Component, OnInit } from '@angular/core';
import { Sistema } from '../../shared/model/sistema';
import { Rol } from '../../shared/model/rol';
import { SistemaRol } from '../../shared/model/sistemaRol';
import { UsuarioNuevo } from '../../shared/model/UsuarioNuevo';
import { Mensajes } from '../../shared/interfaces/mensajes';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CredencialesService } from '../../shared/services/operation/credenciales.service';
import { RolesService } from '../../shared/services/operation/roles.service';
import { ToastrService } from 'ngx-toastr';
import { UtilsService } from '../../shared/services/operation/utils.service';
import { UsuarioService } from '../../shared/services/operation/usuario.service';
import { KeycloakService } from '../../shared/services/keycloak/keycloak.service';
import { environment } from '../../../environments/environment';

@Component({
    selector: 'app-roles-usuario',
    templateUrl: './roles-usuario.component.html'
})
export class RolesUsuarioComponent implements OnInit {

    rolesForm: FormGroup;
    spinner = false;
    sistemas: Array<Sistema> = [];
    roles: Array<Rol> = [];
    sistemaRoles: Array<SistemaRol> = [];
    sistemaRolesMostrados: Array<SistemaRol> = [];
    usuarioNuevo: UsuarioNuevo;
    sistemasListos = false;
    spinnerRoles = false;

    optionsDataTable: any = {
        paging: false,
        info: false,
        sortable: false,
        ordering: true,
        searching: false,
        scrollY: '50vh',
        scrollCollapse: true,
        language: {
            search: Mensajes.ETIQUETA_BUSCAR,
            emptyTable: Mensajes.ETIQUETA_TABLAVACIA
        }
    };

    // labels
    tituloRoles: string = Mensajes.ETIQUETA_TITULO_ROLES;
    lblSistema: string = Mensajes.ETIQUETA_SISTEMA;
    lblRol: string = Mensajes.ETIQUETA_ROL;
    lblCancelar: string = Mensajes.ETIQUETA_CANCELAR;
    lblQuitar: string = Mensajes.ETIQUETA_QUITAR;

    constructor(private formBuilder: FormBuilder,
        private creencialService: CredencialesService,
        private rolesService: RolesService,
        private toastr: ToastrService,
        private utils: UtilsService,
        private usuarioService: UsuarioService) {
    }

    ngOnInit() {
        if (this.creencialService.validaLogin()) {

            this.rolesForm = this.formBuilder.group({
                sistema: [0, Validators.required],
                rol: [0, Validators.required]
            });

            this.usuarioNuevo = this.usuarioService.getNuevoUsuario();

            if (this.usuarioNuevo != null || this.usuarioNuevo !== undefined) {

                if (this.usuarioNuevo.sistemasRoles !== undefined) {
                    this.sistemasListos = true;
                    this.sistemaRoles = this.usuarioNuevo.sistemasRoles;
                    this.sistemaRolesMostrados = this.sistemaRoles.filter(rol => rol.descSistema === 'SCI UI ADMIN');
                }

                this.sistemas = this.comparaSistemas(this.creencialService.getCredencial().sistemas);
                this.sistemas = this.sistemas.filter(sistema => sistema.nombreCorto === 'SCI_FRONT');

                if (this.sistemas.length > 0) {
                    this.spinner = true;
                    this.rolesService.obtenerRoles(this.sistemas[0].id)
                        .subscribe(data => {
                            this.roles = data.data;
                            this.rolesForm = this.formBuilder.group({
                                sistema: [this.sistemas[0].id, Validators.required],
                                rol: [this.roles[0].idRol, Validators.required]
                            });
                            this.spinner = false;
                        }
                            , error => {
                                const status = error.status;
                                switch (status) {
                                    case 400:
                                        this.utils.mostrarError(error.error.description);
                                        break;
                                    case 401:
                                        this.utils.mostrarError(Mensajes.NO_PERMITIDO);
                                        break;
                                    case 403:
                                        this.utils.mostrarError(Mensajes.PROHIBIDO);
                                        break;
                                    case 404:
                                        this.utils.mostrarError(Mensajes.NO_ENCONTRADO);
                                        break;
                                    default:
                                        console.log(error);
                                        this.utils.mostrarError(Mensajes.NO_ENCONTRADO);
                                        break;
                                }
                                this.spinner = false;
                            });
                } else {
                    this.spinner = false;
                    this.toastr.error(Mensajes.NO_SISTEMAS, 'Error!!!');
                }
            } else {
                this.toastr.error(Mensajes.DATOS_INCOMPLETOS, 'Error!!!');
                var element: HTMLElement = document.getElementById('datos-usuario') as HTMLElement;
                element.click();
            }
        } else {
            KeycloakService.logout();
        }
    }

    selectChangeHandler(event: any) {
        this.spinner = true;
        this.rolesService.obtenerRoles(event.target.value)
            .subscribe(data => {
                this.roles = data.data;
                this.rolesForm = this.formBuilder.group({
                    sistema: [event.target.value, Validators.required],
                    rol: [this.roles[0].idRol, Validators.required]
                });
                this.spinner = false;
            }
                , error => {
                    const status = error.status;
                    switch (status) {
                        case 400:
                            this.utils.mostrarError(error.error.description);
                            break;
                        case 401:
                            this.utils.mostrarError(Mensajes.NO_PERMITIDO);
                            break;
                        case 403:
                            this.utils.mostrarError(Mensajes.PROHIBIDO);
                            break;
                        case 404:
                            this.utils.mostrarError(Mensajes.NO_ENCONTRADO);
                            break;
                        default:
                            console.log(error);
                            this.utils.mostrarError(Mensajes.NO_ENCONTRADO);
                            break;
                    }
                    this.spinner = false;
                });
    }

    agregarRol() {

        let sis: number = this.rolesForm.get('sistema').value;
        let rol: number = this.rolesForm.get('rol').value;
        let repetido = false;

        if (this.sistemaRoles.length > 0) {
            for (let sisr of this.sistemaRoles) {
                if (sisr.idSistema == sis) {
                    repetido = true;
                }
            }
        }

        if (!repetido) {
            let sistemaRol: SistemaRol = new SistemaRol();
            sistemaRol.idSistema = sis;
            sistemaRol.idRol = rol;

            for (let sis of this.sistemas) {
                if (sis.id == sistemaRol.idSistema) {
                    sistemaRol.descSistema = sis.descripcion;
                }
            }

            for (let rol of this.roles) {
                if (rol.idRol == sistemaRol.idRol) {
                    sistemaRol.descRol = rol.descripcion;
                }
            }

            this.sistemaRoles.push(sistemaRol);
            this.sistemaRolesMostrados.push(sistemaRol);
            this.agregarTodosLosSistemas();
        } else {
            this.toastr.warning(Mensajes.SISTEMA_REPETIDO, 'Oops!!');
        }
    }

    quitarRol(rol: any) {
        const indexM = this.sistemaRolesMostrados.indexOf(rol, 0);
        this.sistemaRolesMostrados.splice(indexM, 1);
        this.sistemaRoles = [];
        this.sistemasListos = false;
    }

    regresar() {
        this.usuarioNuevo.sistemasRoles = this.sistemaRoles;
        this.usuarioService.setNuevoUsuario(this.usuarioNuevo);
        const element: HTMLElement = document.getElementById('datos-usuario') as HTMLElement;
        element.click();
    }

    onSubmit() {
        this.usuarioNuevo.sistemasRoles = this.sistemaRoles;
        this.usuarioService.setNuevoUsuario(this.usuarioNuevo);
        const element: HTMLElement = document.getElementById('resumen-usuario') as HTMLElement;
        element.click();
    }

    comparaSistemas(sisUsuario: Array<any>): Array<Sistema> {
        let sistemasSci = environment.sistemas;
        let sisTmp: Array<Sistema> = [];
        sistemasSci.filter(item => {
            sisUsuario.filter(itemu => {
                if (item === itemu.nombreCorto) {
                    sisTmp.push(itemu);
                }
            });
        });
        return sisTmp;
    }

    agregarTodosLosSistemas() {
        this.spinnerRoles = true;
        let sistemas: Array<Sistema> = this.comparaSistemas(this.creencialService.getCredencial().sistemas);
        sistemas = sistemas.filter(sistema => sistema.nombreCorto !== 'SCI_FRONT');
        sistemas.forEach(sistema => {
            this.rolesService.obtenerRoles(sistema.id)
                .subscribe(data => {
                let roles: Array<Rol> = data.data;
                    let sistemaRol: SistemaRol = new SistemaRol();
                    sistemaRol.idSistema = sistema.id;
                    sistemaRol.idRol = roles[0].idRol;
                    sistemaRol.descSistema = sistema.descripcion;
                    sistemaRol.descRol = roles[0].descripcion;
                    this.sistemaRoles.push(sistemaRol);
                    if(sistemas.length === this.sistemaRoles.length){
                        this.sistemasListos = true;
                        this.spinnerRoles = false;
                    }
                }
                    , error => {
                        const status = error.status;
                        switch (status) {
                            case 400:
                                this.utils.mostrarError(error.error.description);
                                break;
                            case 401:
                                this.utils.mostrarError(Mensajes.NO_PERMITIDO);
                                break;
                            case 403:
                                this.utils.mostrarError(Mensajes.PROHIBIDO);
                                break;
                            case 404:
                                this.utils.mostrarError(Mensajes.NO_ENCONTRADO);
                                break;
                            default:
                                console.log(error);
                                this.utils.mostrarError(Mensajes.NO_ENCONTRADO);
                                break;
                        }
                        this.spinner = false;
                    });
        });
    }

}
