import {Component, OnInit} from '@angular/core';
import {EntidadExt} from '../../shared/model/entidad_ext';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {EntidadesService} from '../../shared/services/operation/entidades.service';
import {UtilsService} from '../../shared/services/operation/utils.service';
import {CredencialesService} from '../../shared/services/operation/credenciales.service';
import {UsuarioService} from '../../shared/services/operation/usuario.service';
import {KeycloakService} from '../../shared/services/keycloak/keycloak.service';
import {Mensajes} from '../../shared/interfaces/mensajes';
import {Usuario} from '../../shared/model/usuario';
import {UsuarioNuevo} from '../../shared/model/UsuarioNuevo';

@Component({
  selector: 'app-datos-usuario',
  templateUrl: './datos-usuario.component.html'
})
export class DatosUsuarioComponent implements OnInit {

  showEntidad = false;
  entidades: Array<EntidadExt> = [];
  resultadosEnts = false;
  spinner = false;
  usuarioTmp: UsuarioNuevo;

  usuarioForm: FormGroup;
  // labels
  nombre: string = Mensajes.ETIQUETA_NOMBRE;
  apaterno: string = Mensajes.ETIQUETA_APATERNO;
  amaterno: string = Mensajes.ETIQUETA_AMATERNO;
  correo: string = Mensajes.ETIQUETA_CORREO;
  entidad: string = Mensajes.ETIQUETA_ENTIDAD;
  esEntidad: string = Mensajes.ETIQUETA_ES_ENTIDAD;
  tituloDatos: string = Mensajes.ETIQUETA_TITULO_DATOS;
  nombreRequerido: string = Mensajes.NOMBRE_REQUERIDO;
  aPaternoRequerido: string = Mensajes.APATERNO_REQUERIDO;
  correoRequerido: string = Mensajes.CORREO_REQUERIDO;
  correoInvalido: string = Mensajes.CORREO_INVALIDO;

  constructor(public formBuilder: FormBuilder,
              private entidadesService: EntidadesService,
              private utils: UtilsService,
              private credencialService: CredencialesService,
              private usuarioService: UsuarioService) { }

  ngOnInit() {
    if (this.credencialService.validaLogin()) {
      this.usuarioTmp = this.usuarioService.getNuevoUsuario();
      if (this.usuarioTmp === undefined || this.usuarioTmp == null) {

        this.usuarioTmp = new UsuarioNuevo();
        this.usuarioForm = this.formBuilder.group({
          nombre:  new FormControl('', Validators.required),
          apellidoPaterno:  new FormControl('', Validators.required),
          apellidoMaterno:  new FormControl(''),
          correo:  new FormControl('', [Validators.required, Validators.email]),
          entOpt: [false],
          entidadExterna: [''],
        });

      } else {
        if (this.usuarioTmp.usuario.entidadExterna !== undefined) {
          this.spinner = true;
          this.getEntidades();
        }
          this.usuarioForm = this.formBuilder.group({
            nombre: new FormControl(this.usuarioTmp.usuario.nombre, Validators.required),
            apellidoPaterno: new FormControl(this.usuarioTmp.usuario.apellidoPaterno, Validators.required),
            apellidoMaterno: new FormControl(this.usuarioTmp.usuario.apellidoMaterno),
            correo: new FormControl(this.usuarioTmp.usuario.correo, [Validators.required, Validators.email]),
            entOpt: [this.usuarioTmp.usuario.entidadExterna !== undefined],
            entidadExterna: [this.usuarioTmp.usuario.entidadExterna
             !== undefined ? this.usuarioTmp.usuario.entidadExterna.idEntidadExterna : '']
          });
      }

    } else {
      KeycloakService.logout();
    }
  }

  selectChangeHandler(event: any) {
    if (event.target.value) {
      this.spinner = true;
      this.getEntidades();
    } else {
      this.entidades = [];
      this.resultadosEnts = false;
      this.showEntidad = false;
    }
  }

  getEntidades() {
    this.entidadesService.obtenerEntidades()
        .subscribe(data => {
              this.entidades = data.data;
              this.entidades = this.entidades.filter(entidad => entidad.estatus === true);
              this.resultadosEnts = true;
              this.spinner = false;
              this.showEntidad = true;
            }, error1 => {
              const status = error1.status;
              switch (status) {
                case 400:
                  this.utils.mostrarError(error1.error.description);
                  break;
                case 401:
                  this.utils.mostrarError(Mensajes.NO_PERMITIDO);
                  KeycloakService.logout();
                  break;
                case 403:
                  this.utils.mostrarError(Mensajes.PROHIBIDO);
                  break;
                case 404:
                  this.utils.mostrarError(Mensajes.NO_ENCONTRADO);
                  break;
                default:
                  console.log(error1);
                  this.utils.mostrarError(Mensajes.NO_ENTIDADES);
                  break;
              }
              this.spinner = false;
            }
        );
  }

  onSubmit() {
    if (this.usuarioForm.valid) {

      let newUsuario: Usuario = new Usuario();
      newUsuario.nombre = this.usuarioForm.get('nombre').value;
      newUsuario.apellidoPaterno = this.usuarioForm.get('apellidoPaterno').value;
      newUsuario.apellidoMaterno = this.usuarioForm.get('apellidoMaterno').value;
      newUsuario.correo = this.usuarioForm.get('correo').value;
      if (this.usuarioForm.get('entOpt').value) {
        newUsuario.entidadExterna = new EntidadExt(this.usuarioForm.get('entidadExterna').value);
      }

      let usuarioNuevo: UsuarioNuevo = new UsuarioNuevo();
      usuarioNuevo.usuario = newUsuario;
      if (this.usuarioTmp.sistemasRoles !== undefined) {
        usuarioNuevo.sistemasRoles = this.usuarioTmp.sistemasRoles;
      }
      this.usuarioService.setNuevoUsuario(usuarioNuevo);
      const element: HTMLElement = document.getElementById('roles-usuario') as HTMLElement;
      element.click();
    }
  }
}
