import {Component, OnInit} from '@angular/core';
import {Mensajes} from '../../shared/interfaces/mensajes';
import {CredencialesService} from '../../shared/services/operation/credenciales.service';
import {SistemaService} from '../../shared/services/operation/sistema.services';
import {PermisosService} from '../../shared/services/operation/permisos.service';
import {UsuarioService} from '../../shared/services/operation/usuario.service';
import {FacultadesService} from '../../shared/services/operation/facultades.services';
import {UtilsService} from '../../shared/services/operation/utils.service';
import {Location} from '@angular/common';
import {Router} from '@angular/router';
import {Credencial} from '../../shared/model/credencial';
import {Usuario} from '../../shared/model/usuario';
import {Sistema} from '../../shared/model/sistema';
import {ObjetosUsuario} from './../../shared/model/objetos_usuario';
import {Facultad} from '../../shared/model/facultad';
import swal from 'sweetalert2';
import { environment } from 'src/environments/environment';
import { UsuarioServicioWrapper } from 'src/app/shared/model/usuario-servicio-wrapper';

@Component({
    selector: 'app-facultades',
    templateUrl: './facultades.component.html'
})
export class FacultadesComponent implements OnInit {

    header: string;
    badge: string;
    usuarioLogeado: Credencial;
    usuarioElegido: Usuario = new Usuario();
    sistemasFinal: Array<Sistema>;
    objetos: Array<ObjetosUsuario> = [];
    activarUsuario = false;
    spinner = false;
    optionsDataTable: any = {
        select: 'multi',
        paging: false,
        info: false,
        sortable: false,
        ordering: true,
        searching: false,
        scrollY: '50vh',
        scrollCollapse: true,
        language: {
            search: Mensajes.ETIQUETA_BUSCAR,
            //infoEmpty: Mensajes.ETIQUETA_TABLAVACIA
        }
    };

    constructor(private credencialService: CredencialesService,
                private sistemaService: SistemaService,
                private usuarioService: UsuarioService,
                private permisosService: PermisosService,
                private facultadesService: FacultadesService,
                private utils: UtilsService,
                private router: Router,
                private _location: Location) {
        this.header = Mensajes.ETIQUETA_ADMIN_FACULTADES;
        this.badge = Mensajes.ETIQUETA_ADMIN_BADGE;
    }

    ngOnInit() {
        if (this.credencialService.validaLogin()) {
            this.usuarioLogeado = this.credencialService.getCredencial();
            this.usuarioElegido = this.usuarioService.getUsuario();
            this.spinner = true;
            if (this.usuarioElegido.estatus) {
                this.consultaSistemas(this.usuarioElegido.username);
            } else {
                this.activarUsuario = true;
                this.spinner = false;
            }
        } else {
            this.router.navigate(['/']);
        }
    }

    consultaSistemas(username: string) {

        this.sistemaService.obtenerSistemas(username)
            .subscribe(
                data => {
                    if (data.data != null || data.data !== undefined) {
                        if (data.data.length > 0) {
                            this.sistemasFinal = this.comparaSistemas(data.data);
                            for (const sist of this.sistemasFinal) {
                                if (this.usuarioElegido.rol.esAdministrador && sist.nombreCorto === 'SCI_FRONT') {
                                    this.consultaFacultades(sist, this.usuarioElegido.username);
                                } else if (!this.usuarioElegido.rol.esAdministrador) {
                                    this.consultaFacultades(sist, this.usuarioElegido.username);
                                }
                            }
                        } else {
                            this.spinner = false;
                            this.utils.mostrarAviso(Mensajes.NO_FACULTADES);
                            this._location.back();
                        }
                    } else {
                        this.spinner = false;
                        this.utils.mostrarAviso(Mensajes.NO_FACULTADES);
                        this._location.back();
                    }
                },
                error => {
                    const status = error.status;
                    switch (status) {
                        case 400:
                            this.utils.mostrarError(error.error.description);
                            break;
                        case 401:
                            this.utils.mostrarError(Mensajes.NO_PERMITIDO);
                            break;
                        case 403:
                            this.utils.mostrarError(Mensajes.PROHIBIDO);
                            break;
                        case 404:
                            this.utils.mostrarError(Mensajes.NO_ENCONTRADO);
                            break;
                        default:
                            console.log(error);
                            this.utils.mostrarError(Mensajes.NO_ENCONTRADO);
                            break;
                    }
                }
            );
    }

    comparaSistemas(sisUsuario: Array<any>): Array<Sistema> {
        let sistemasSci = environment.sistemas;
        let sisTmp: Array<Sistema> = [];
        sistemasSci.filter(item => {
            sisUsuario.filter(itemu => {
                if (item === itemu.nombreCorto) {
                    sisTmp.push(itemu);
                }
            });
        });
        return sisTmp;
    }

    comparaFacultades(facsUsuario: Array<Facultad>, facsUniverso: Array<Facultad>): Array<Facultad> {
        for (const entry of facsUsuario) {
            const index = facsUniverso.findIndex(value => entry.idFacultad === value.idFacultad);
            facsUniverso.splice(index, 1);
        }
        return facsUniverso;
    }

    consultaFacultades(sistema: Sistema, usuario: string) {

        this.permisosService.obtenerPermisos(usuario, sistema.id)
            .subscribe(
                async data => {
                    const objetoUsuario: ObjetosUsuario = new ObjetosUsuario();
                    objetoUsuario.sistema = sistema;
                    objetoUsuario.username = usuario;
                    if (data.data != null || data.data !== undefined) {
                        objetoUsuario.permiso = data.data[0];
                        if (objetoUsuario.permiso !== undefined) {
                            objetoUsuario.permiso.facultades = objetoUsuario.permiso.facultades.filter(item => item.estatus);
                        }
                    }
                    await this.consultaFacultadesUniverso(objetoUsuario, sistema.id);
                },
                err => {
                    this.spinner = false;
                    this.utils.mostrarError(err.message);
                }
            );
    }

    consultaFacultadesUniverso(objetoUsuario: ObjetosUsuario, sistema: number) {

        this.facultadesService.obtenerFacultadesSistema(sistema)
            .subscribe(
                data => {
                    if (data.data.length > 0) {
                        if (objetoUsuario.permiso !== undefined) {
                            this.comparaFacultades(objetoUsuario.permiso.facultades, data.data);
                            const tmpFacs: Array<Facultad> = [];
                            for (const entry of data.data) {
                                if (entry.estatus) {
                                    tmpFacs.push(entry);
                                }
                            }
                            objetoUsuario.universo = tmpFacs.filter(item => item.estatus);
                            this.objetos.push(objetoUsuario);
                            this.spinner = false;
                        } else {
                            this.spinner = false;
                            this.utils.mostrarAviso(Mensajes.NO_FACULTADES);
                        }
                    } else {
                        this.spinner = false;
                    }
                },
                err => {
                    this.spinner = false;
                    this._location.back();
                    this.utils.mostrarError(err.message);
                }
            );
    }

    regresar() {
        this.router.navigate(['/main-dashboard/admin-usuarios']);
    }

    adminSistemas() {
        this.router.navigate(['/main-dashboard/admin-sistemas']);
    }

    agregarFacultad(sistema: number, permiso: number) {
        const table = $('#ts' + sistema).DataTable();
        const selectedValue = table.rows({selected: true}).data();
        if (selectedValue !== undefined) {
            if (selectedValue.length > 0) {
                let facs: Array<Facultad> = [];
                let facultadesString = '';
                for (let i = 0; i < selectedValue.length; i++) {
                    facs.push(new Facultad(+selectedValue[i][0]));
                    facultadesString += selectedValue[i][1] + ' ';
                }
                swal({
                    text: '¿Agregar Facultad(es) ' + facultadesString + '?',
                    type: 'question',
                    confirmButtonText: 'Aceptar',
                    cancelButtonText: 'Cancelar',
                    showCancelButton: true,
                    focusCancel: false,
                    allowOutsideClick: false,
                    showLoaderOnConfirm: true,
                    preConfirm: (result) => {
                        if (result) {
                            this.spinner = true;
                            this.facultadesService.guardaFacultad(sistema, this.usuarioElegido.username, permiso, facs)
                                .subscribe(
                                    data => {
                                        swal({
                                            type: 'success',
                                            title: 'Listo',
                                            text: Mensajes.FACULTADES_EXITO_AGREGAR
                                        }).then(() => {
                                            this.sistemasFinal = null;
                                            this.objetos = [];
                                            setTimeout(() => {
                                                this.consultaSistemas(this.usuarioElegido.username);
                                            }, 200);
                                        });
                                    },
                                    error1 => {
                                        let texto;
                                        const status = error1.status;
                                        switch (status) {
                                            case 400:
                                                texto = error1.error.description;
                                                break;
                                            case 401:
                                                texto = Mensajes.NO_PERMITIDO;
                                                break;
                                            case 403:
                                                texto = Mensajes.PROHIBIDO;
                                                break;
                                            case 404:
                                                texto = Mensajes.NO_ENCONTRADO;
                                                break;
                                            case 406:
                                                texto = Mensajes.NO_ACEPTADO;
                                                break;
                                            default:
                                                console.log(error1);
                                                texto = Mensajes.ERROR_SOLICITUD;
                                                break;
                                        }
                                        swal({
                                            type: 'error',
                                            title: 'Error',
                                            text: texto
                                        });
                                        this.spinner = false;
                                    }
                                );
                        }
                    }
                });
            } else {
                this.spinner = false;
                this.utils.mostrarError(Mensajes.SELECCIONAR_FACULTADES_AGREGAR);
            }

        } else {
            this.spinner = false;
            this.utils.mostrarError(Mensajes.SELECCIONAR_FACULTADES_AGREGAR);
        }
    }

    quitarFacultad(sistema: number, permiso: number) {
        const table = $('#tu' + sistema).DataTable();
        const selectedValue = table.rows({selected: true}).data();
        if (selectedValue !== undefined) {
            if (selectedValue.length > 0) {
                const facs: Array<Facultad> = [];
                let facultadesString = '';
                for (let i = 0; i < selectedValue.length; i++) {
                    facs.push(new Facultad(+selectedValue[i][0]));
                    facultadesString += selectedValue[i][1] + ' ';
                }
                swal({
                    text: '¿Quitar Facultad(es) ' + facultadesString + '?',
                    type: 'question',
                    confirmButtonText: 'Aceptar',
                    cancelButtonText: 'Cancelar',
                    showCancelButton: true,
                    focusCancel: false,
                    allowOutsideClick: false,
                    showLoaderOnConfirm: true,
                    preConfirm: (result) => {
                        if (result) {
                            this.spinner = true;
                            this.facultadesService.borraFacultad(sistema, this.usuarioElegido.username, permiso, facs)
                                .subscribe(
                                    data => {
                                        swal({
                                            type: 'success',
                                            title: 'Listo',
                                            text: Mensajes.FACULTADES_EXITO_QUITAR
                                        }).then((result) => {
                                            this.sistemasFinal = null;
                                            this.objetos = [];
                                            setTimeout(() => {
                                                this.consultaSistemas(this.usuarioElegido.username);
                                            }, 200);
                                        });
                                    },
                                    error1 => {
                                        let texto;
                                        const status = error1.status;
                                        switch (status) {
                                            case 400:
                                                texto = error1.error.description;
                                                break;
                                            case 401:
                                                texto = Mensajes.NO_PERMITIDO;
                                                break;
                                            case 403:
                                                texto = Mensajes.PROHIBIDO;
                                                break;
                                            case 404:
                                                texto = Mensajes.NO_ENCONTRADO;
                                                break;
                                            case 406:
                                                texto = Mensajes.NO_ACEPTADO;
                                                break;
                                            default:
                                                console.log(error1);
                                                texto = Mensajes.ERROR_SOLICITUD;
                                                break;
                                        }

                                        swal({
                                            type: 'error',
                                            title: 'Error',
                                            text: texto
                                        });
                                        this.spinner = false;
                                    }
                                );
                        } else {
                            this.spinner = false;
                        }
                    }
                });
            } else {
                this.utils.mostrarError(Mensajes.SELECCIONAR_FACULTADES_ELIMINAR);
            }
        } else {
            this.utils.mostrarError(Mensajes.SELECCIONAR_FACULTADES_AGREGAR);
        }
    }

    guardarUsuario() {

        if (this.usuarioElegido.estatus) {
            swal({
                text: '¿Quiere activar al usuario?',
                type: 'question',
                confirmButtonText: 'Aceptar',
                cancelButtonText: 'Cancelar',
                showCancelButton: true,
                focusCancel: false,
                allowOutsideClick: false,
                showLoaderOnConfirm: true,
                preConfirm: (result) => {
                    if (result) {
                        this.spinner = true;
                        this.usuarioService.activarUsuario(this.usuarioElegido)
                            .subscribe(
                                data => {
                                    swal({
                                        type: 'success',
                                        title: 'Listo',
                                        text: Mensajes.EDITAR_USUARIO_EXITO
                                    });
                                    this.activarUsuario = false;
                                    this.sistemasFinal = null;
                                    this.objetos = [];
                                    setTimeout(() => {
                                        this.consultaSistemas(this.usuarioElegido.username);
                                    }, 200);
                                    this.spinner = false;
                                },
                                error1 => {

                                    let texto;
                                    const status = error1.status;
                                    switch (status) {
                                        case 400:
                                            texto = error1.error.description;
                                            break;
                                        case 401:
                                            texto = Mensajes.NO_PERMITIDO;
                                            break;
                                        case 403:
                                            texto = Mensajes.PROHIBIDO;
                                            break;
                                        case 404:
                                            texto = Mensajes.NO_ENCONTRADO;
                                            break;
                                        case 406:
                                            texto = Mensajes.NO_ACEPTADO;
                                            break;
                                        default:
                                            console.log(error1);
                                            texto = Mensajes.ERROR_SOLICITUD;
                                            break;
                                    }
                                    this.spinner = false;
                                    swal({
                                        type: 'error',
                                        title: 'Error',
                                        text: texto
                                    });
                                }
                            );
                    }
                }
            });
        } else {
            this.spinner = false;
            swal({
                type: 'warning',
                title: Mensajes.EDITAR_USUARIO_SIN_CAMBIOS
            });
        }
    }

    limpiarCache() {
        swal({
            text: '¿Quiere limpiar cache del usuario?',
            type: 'question',
            confirmButtonText: 'Aceptar',
            cancelButtonText: 'Cancelar',
            showCancelButton: true,
            focusCancel: false,
            allowOutsideClick: false,
            showLoaderOnConfirm: true,
            preConfirm: async (result) => {
                if (result) {
                    this.spinner = true;
                    let response = new UsuarioServicioWrapper();
                    try{
                        let cachePolizas = await this.usuarioService.limpiarCachePolizasVigentes(this.usuarioElegido.username);
                        let cacheOcra = await this.usuarioService.limpiarCacheOCRA(this.usuarioElegido.username);
                        let cachePerdida = await this.usuarioService.limpiarCachePerdidaTotal(this.usuarioElegido.username);
                        let cacheValuacion = await this.usuarioService.limpiarCacheValuaciones(this.usuarioElegido.username);

                        response.status = true;
                        return response;
                    } catch (e) {
                        const status = e.status;
                        let errorMessage = '';
                        switch (status) {
                            case 400:
                            errorMessage = e.error.description;
                            break;
                            case 401:
                            errorMessage = Mensajes.NO_PERMITIDO;
                            break;
                            case 403:
                            errorMessage = Mensajes.PROHIBIDO;
                            break;
                            case 404:
                            errorMessage = Mensajes.NO_ENCONTRADO;
                            break;
                            case 406:
                            errorMessage = Mensajes.NO_ACEPTADO;
                            break;
                            case 409:
                            errorMessage = Mensajes.CONFLICTED;
                            break;
                            case 422:
                            errorMessage = Mensajes.INFO_INCORRECTA;
                            break;
                            default:
                            errorMessage = Mensajes.ERROR_SOLICITUD;
                            break;
                        }
                        response.status = false;
                        response.errorMessage = errorMessage;
                        return response;
                    }

                }
            }
        }).then(result => {
            if (result.value.status) {
              swal({
                type: 'success',
                title: 'Listo',
                html: Mensajes.OPERACION_EXITOSA
              }).then(() => {
                this.spinner = false;
              });
            } else {
              this.spinner = false;
              swal({
                type: 'error',
                title: 'Error',
                text: result.value.errorMessage
              });
            }
          });;
    }

}
