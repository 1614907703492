// Librerias
import {Component, OnInit} from '@angular/core';
import swal from 'sweetalert2';
import {Router} from '@angular/router';
// Modelo
import {Mensajes} from '../../shared/interfaces/mensajes';
import {environment} from '../../../environments/environment';
import {Credencial} from '../../shared/model/credencial';
import {Usuario} from '../../shared/model/usuario';
import {Sistema} from '../../shared/model/sistema';
import * as configuracion from '../../shared/services/configurations';
// Services
import {UsuarioService} from '../../shared/services/operation/usuario.service';
import {UtilsService} from '../../shared/services/operation/utils.service';
import {CredencialesService} from '../../shared/services/operation/credenciales.service';
import {KeycloakService} from '../../shared/services/keycloak/keycloak.service';

@Component({
    selector: 'app-usuarios',
    templateUrl: './usuarios.component.html'
})
export class UsuariosComponent implements OnInit {

    // atributtes
    usuarioLogeado: Credencial = new Credencial();
    mostrarSecret = false;
    usuDisponibles: Array<Usuario> = [];
    finalUsuarios: Array<Usuario> = [];
    sistemasIncluidos = '';
    resultados = false;
    facultades: any[] = [];
    acciones: any;
    spinner = false;
    idValuacion = environment.idvaluacion;

    // labels
    header: string;

    // opciones tabla
    optionsDataTable: any = {
        dom: 'Bfrtip',
        buttons: [
            {extend: 'excel', text: Mensajes.ETIQUETA_DESCARGAR}
        ],
        select: false,
        paging: true,
        info: false,
        order: [[0, 'asc']],
        ordering: true,
        lengthChange: true,
        scrollY: '50vh',
        scrollCollapse: true,
        pageLength: 15,
        language: {
            search: Mensajes.ETIQUETA_BUSCAR,
            emptyTable: Mensajes.ETIQUETA_TABLAVACIA,
            paginate: {
                next: Mensajes.ETIQUETA_SIGUIENTE,
                previous: Mensajes.ETIQUETA_ANTERIOR,
            }
        }

    };

    constructor(private usuarioService: UsuarioService,
                private utils: UtilsService,
                private credencialService: CredencialesService,
                private router: Router) {

        this.facultades = configuracion.FACULTADES;
        this.header = Mensajes.ETIQUETA_CONSULTA_USUARIOS;
    }

    ngOnInit() {
        if (this.credencialService.validaLogin()) {
            this.usuarioLogeado = this.credencialService.getCredencial();
            if (this.usuarioLogeado.rol === 'ADMIN_AMIS') {
                this.mostrarSecret = true;
            }
            this.spinner = true;
            this.consultaUsuarios(this.usuarioLogeado.sistemas);
        } else {
            KeycloakService.logout();
        }

        this.acciones = this.consultaFacultades();
    }

    async consultaUsuarios(sistemas: Array<Sistema>) {

      let sistemasSci = await this.comparaSistemas(sistemas);

        for (const sis of sistemasSci) {
            this.sistemasIncluidos += sis.descripcion + ', ';
            let res = await this.usuarioService.obtenerUsuarios(sis.id);
            if (res != null || res !== undefined) {
                this.concatenaArreglo(res.data, sis);
            }
            let resServicio = await this.usuarioService.obtenerUsuariosServicio(sis.id);
            if (resServicio != null || resServicio !== undefined) {
                this.concatenaArreglo(resServicio.data, sis);
            }
        }
        this.manejarDatos();
    }

    concatenaArreglo(usuarios: Array<Usuario>, sistema: Sistema) {

        for (let usuario of usuarios) {
            usuario.sistema = sistema;
            if (usuario.rol.esAdministrador && sistema.nombreCorto === 'SCI_FRONT') {
                this.usuDisponibles.push(usuario);
            } else if (!usuario.rol.esAdministrador) {
                this.usuDisponibles.push(usuario);
            }

        }
    }

    manejarDatos() {
        if (this.usuDisponibles.length > 0) {
            this.finalUsuarios = this.usuDisponibles;
            this.resultados = true;
            this.spinner = false;
        } else {
            this.spinner = false;
            this.utils.mostrarAviso(Mensajes.NO_USUARIOS);
            this.router.navigate(['home', {outlets: {app: null}}]);
        }
    }

    consultarUsuario(usuario: Usuario) {
        const idValuacion = environment.idvaluacion;
        this.usuarioService.setUsuario(usuario);
        this.router.navigate(['/main-dashboard/consulta-facultades']);
    }

    editarUsuario(usuario: Usuario) {
        this.usuarioService.setUsuario(usuario);
        this.router.navigate(['/main-dashboard/admin-facultades']);
    }

    eliminarUsuario(usuario: Usuario) {
        swal({
            text: '¿Eliminar Usuario ' + usuario.nombre + '?',
            type: 'question',
            confirmButtonText: 'Aceptar',
            showCancelButton: true,
            focusCancel: false,
            allowOutsideClick: false,
            showLoaderOnConfirm: true,
            preConfirm: (result) => {
                if (result) {
                    this.spinner = true;
                    this.usuarioService.eliminarUsuario(usuario)
                        .subscribe(
                            data => {
                                swal({
                                    type: 'success',
                                    title: 'Listo',
                                    text: Mensajes.OPERACION_EXITOSA
                                });
                                this.spinner = false;
                                const index = this.finalUsuarios.indexOf(usuario, 0);
                                this.finalUsuarios[index].estatus = false;
                            },
                            error1 => {
                                let texto;
                                const status = error1.status;
                                switch (status) {
                                    case 400:
                                        texto = error1.error.description;
                                        break;
                                    case 401:
                                        this.utils.mostrarError(Mensajes.NO_PERMITIDO);
                                        KeycloakService.logout();
                                        break;
                                    case 403:
                                        texto = Mensajes.PROHIBIDO;
                                        break;
                                    case 404:
                                        texto = Mensajes.NO_ENCONTRADO;
                                        break;
                                    case 406:
                                        texto = Mensajes.NO_ACEPTADO;
                                        break;
                                    default:
                                        console.log(error1);
                                        texto = Mensajes.ERROR_SOLICITUD;
                                        break;
                                }
                                this.spinner = false;
                                swal({
                                    type: 'error',
                                    title: 'Error',
                                    text: texto
                                });
                            }
                        );
                }
            }
        });
    }

    consultaFacultades(): any {
        const acciones: any[] = [];
        for (const fac of this.facultades) {
            for (const rol of fac.permisosRol) {
                this.usuarioLogeado.facultades.forEach(facultad => {
                    if (rol === facultad.nombre) {
                        acciones.push(fac);
                    }
                });
            }
        }

        return acciones;
    }

  comparaSistemas(sisUsuario: Array<any>): Array<Sistema> {
    let sistemasSci= environment.sistemas;
    let sisTmp: Array<Sistema> = [];
    sistemasSci.filter(item => {
      sisUsuario.filter(itemu => {
        if (item === itemu.nombreCorto) {
          sisTmp.push(itemu);
        }
      });
    });
    return sisTmp;
  }

  regenerarSecret(idSistema: number, usuarioUUID, username: string) {
    swal({
        text: '¿Desea generar su SECRET?',
        type: 'question',
        confirmButtonText: 'Aceptar',
        showCancelButton: true,
        focusCancel: false,
        allowOutsideClick: false,
        showLoaderOnConfirm: true,
        preConfirm: (result) => {
            if (result) {
                this.spinner = true;
                this.usuarioService.regenerarSecret(idSistema, usuarioUUID)
                    .subscribe(
                        data => {
                            this.usuarioService.obtenerUsuario(idSistema, username).subscribe(res => {
                                swal({
                                    type: 'success',
                                    title: 'Listo',
                                    html: Mensajes.OPERACION_EXITOSA + '<br/>'
                                    + 'Username: ' + res.data.username + '<br/>'
                                    + 'Secret: ' + res.data.apellidoMaterno
                                });
                                this.spinner = false;
                            }, error => {
                                let texto;
                            const status = error.status;
                            switch (status) {
                                case 400:
                                    texto = error.error.description;
                                    break;
                                case 401:
                                    this.utils.mostrarError(Mensajes.NO_PERMITIDO);
                                    KeycloakService.logout();
                                    break;
                                case 403:
                                    texto = Mensajes.PROHIBIDO;
                                    break;
                                case 404:
                                    texto = Mensajes.NO_ENCONTRADO;
                                    break;
                                case 406:
                                    texto = Mensajes.NO_ACEPTADO;
                                    break;
                                default:
                                    console.log(error);
                                    texto = Mensajes.ERROR_SOLICITUD;
                                    break;
                            }
                            this.spinner = false;
                            swal({
                                type: 'error',
                                title: 'Error',
                                text: texto
                            });
                            });
                        },
                        error1 => {
                            let texto;
                            const status = error1.status;
                            switch (status) {
                                case 400:
                                    texto = error1.error.description;
                                    break;
                                case 401:
                                    this.utils.mostrarError(Mensajes.NO_PERMITIDO);
                                    KeycloakService.logout();
                                    break;
                                case 403:
                                    texto = Mensajes.PROHIBIDO;
                                    break;
                                case 404:
                                    texto = Mensajes.NO_ENCONTRADO;
                                    break;
                                case 406:
                                    texto = Mensajes.NO_ACEPTADO;
                                    break;
                                default:
                                    console.log(error1);
                                    texto = Mensajes.ERROR_SOLICITUD;
                                    break;
                            }
                            this.spinner = false;
                            swal({
                                type: 'error',
                                title: 'Error',
                                text: texto
                            });
                        }
                    );
            }
        }
    });
  }
}
