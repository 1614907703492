import {Component, OnInit} from '@angular/core';
import {Mensajes} from '../../shared/interfaces/mensajes';
import {UsuarioService} from '../../shared/services/operation/usuario.service';
import {CredencialesService} from '../../shared/services/operation/credenciales.service';
import {PermisosService} from '../../shared/services/operation/permisos.service';
import {RolesService} from '../../shared/services/operation/roles.service';
import {ToastrService} from 'ngx-toastr';
import {UtilsService} from '../../shared/services/operation/utils.service';
import {Router} from '@angular/router';
import {KeycloakService} from '../../shared/services/keycloak/keycloak.service';
import swal from 'sweetalert2';
import {Rol} from '../../shared/model/rol';
import {environment} from '../../../environments/environment';
import {TipoUsuario} from '../../shared/model/tipoUsuario';
import {UsuarioNuevo} from '../../shared/model/UsuarioNuevo';
import {SistemaRol} from '../../shared/model/sistemaRol';

@Component({
    selector: 'app-resumen-usuario',
    templateUrl: './resumen-usuario.component.html'
})
export class ResumenUsuarioComponent implements OnInit {

    usuarioNuevo: UsuarioNuevo;
    sistemasTmp: Array<SistemaRol> = [];
    datosUsuario = false;
    datosRoles = false;
    spinner = false;
    cont = 0;

    optionsDataTable: any = {
        select: false,
        paging: false,
        info: false,
        sortable: false,
        ordering: true,
        searching: false,
        scrollY: '50vh',
        scrollCollapse: true,
        language: {
            search: Mensajes.ETIQUETA_BUSCAR,
            emptyTable: Mensajes.ETIQUETA_TABLAVACIA
        }
    };

    // labels
    tituloResumen: string = Mensajes.ETIQUETA_TITULO_RESUMEN;
    nombre: string = Mensajes.ETIQUETA_NOMBRE;
    correo: string = Mensajes.ETIQUETA_CORREO;
    lblsistema: string = Mensajes.ETIQUETA_SISTEMA;
    lblfacsSistema: string = Mensajes.ETIQUETA_CONSULTA_BADGE_FACULTADES;
    lblfacultad: string = Mensajes.ETIQUETA_FACULTAD;

    constructor(private usuarioService: UsuarioService,
                private credencialService: CredencialesService,
                private permisosService: PermisosService,
                private rolService: RolesService,
                private toastr: ToastrService,
                private utils: UtilsService,
                private router: Router) {
    }

    ngOnInit() {
        if (this.credencialService.validaLogin()) {
            this.usuarioNuevo = this.usuarioService.getNuevoUsuario();
            if (this.usuarioNuevo !== undefined) {
                this.datosUsuario = true;
                this.consultaFacultades(this.usuarioNuevo.sistemasRoles);
                console.log('usuario entrante:', this.usuarioNuevo);
            } else {
                const element: HTMLElement = document.getElementById('datos-usuario') as HTMLElement;
                element.click();
                this.toastr.error(Mensajes.DATOS_INCOMPLETOS, 'Error!!!');
            }
        } else {
            KeycloakService.logout();
        }
    }

    async consultaFacultades(sistemasRoles: Array<SistemaRol>) {
        this.spinner = true;
        for (let sistema of sistemasRoles) {
            await this.permisosService.obtenerPermisosRol(sistema.idSistema, sistema.idRol)
                .subscribe(async data => {
                    sistema.permiso = data.data[0];
                    this.sistemasTmp.push(sistema);
                    this.cont++;
                    await this.validaDatos();
                });
        }
    }

    validaDatos() {
        if (this.cont === this.usuarioNuevo.sistemasRoles.length) {
            if (this.sistemasTmp.length > 0) {
                this.datosRoles = true;
                this.usuarioNuevo.sistemasRoles = this.sistemasTmp;
            } else {
                this.toastr.info(Mensajes.NO_FACULTADES);
            }
            this.spinner = false;
        }
    }

    guardarUsuario() {

        swal({
            text: '¿Guardar Usuario?',
            type: 'question',
            confirmButtonText: 'Aceptar',
            showCancelButton: true,
            focusCancel: false,
            allowOutsideClick: false,
            showLoaderOnConfirm: true,
            preConfirm: async (result): Promise<string> => {
                if (result) {
                    this.spinner = true;
                    try {
                        for (const sistema of this.usuarioNuevo.sistemasRoles) {
                            const usrTmp = JSON.parse(JSON.stringify(this.usuarioNuevo.usuario));
                            usrTmp.username = usrTmp.correo;
                            usrTmp.rol = JSON.parse(JSON.stringify(new Rol(sistema.idRol)));
                            usrTmp.tipUsuario = JSON.parse(JSON.stringify(sistema.idSistema !== environment.sistema ?
                                new TipoUsuario(environment.tipoUSuarioWS) : new TipoUsuario(environment.tipoUsuarioWeb)));
                            let result = await this.usuarioService.insertarUsuarioAdmin(sistema.idSistema, usrTmp);

                            usrTmp.uuid = result.data.uuid;
                            let result2 = await this.permisosService.asociaPermiso(sistema.idSistema, usrTmp.username, sistema.permiso.idPermisoRamo);

                        }
                        return undefined;
                    } catch (e) {
                        const status = e.status;
                        let errorMessage = '';
                        switch (status) {
                            case 400:
                                errorMessage = e.error.description;
                                break;
                            case 401:
                                errorMessage = Mensajes.NO_PERMITIDO;
                                break;
                            case 403:
                                errorMessage = Mensajes.PROHIBIDO;
                                break;
                            case 404:
                                errorMessage = Mensajes.NO_ENCONTRADO;
                                break;
                            case 406:
                                errorMessage = Mensajes.NO_ACEPTADO;
                                break;
                            case 422:
                                errorMessage = Mensajes.INFO_INCORRECTA;
                                break;
                            default:
                                console.log(e);
                                errorMessage = Mensajes.ERROR_SOLICITUD;
                                break;
                        }
                        return errorMessage;
                    }
                }
            }
        }).then(result => {
            if (result.value) {
                swal({
                    type: 'success',
                    title: 'Listo',
                    text: Mensajes.OPERACION_EXITOSA
                }).then(() => {
                    this.spinner = false;
                    this.usuarioNuevo = null;
                    this.sistemasTmp = [];
                    this.datosUsuario = false;
                    this.datosRoles = false;
                    this.usuarioService.setNuevoUsuario(null);
                    this.router.navigate(['/main-dashboard/']);
                });
            } else {
                this.spinner = false;
                swal({
                    type: 'error',
                    title: 'Error',
                    text: result.value
                });
            }
        });
    }

    cancelar() {
        const element: HTMLElement = document.getElementById('roles-usuario') as HTMLElement;
        element.click();
    }

}
