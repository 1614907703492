import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { environment } from 'src/environments/environment';

const endpointURL = environment.seguridadEndpoint;

@Injectable({
  providedIn: 'root'
})
export class EntidadesService {

  constructor(private http: HttpClient) { }

  obtenerCompanias(entidad: number) {
    let predicado = '';
    predicado = predicado.concat('/ent_externas/');
    predicado = predicado.concat(entidad.toString());
    predicado = predicado.concat('/companias/');
    const endpoint = endpointURL + predicado;
    return this
        .http
        .get<any>(endpoint);
  }

  obtenerEntidades() {
    let predicado = '';
    predicado = predicado.concat('/ent_externas');
    const endpoint = endpointURL + predicado;
    return this
        .http
        .get<any>(endpoint);
  }
}
