import {Component, Input, OnInit} from '@angular/core';
import * as config from '../../shared/services/configurations';
import {environment} from '../../../environments/environment';
import { CredencialesService } from 'src/app/shared/services/operation/credenciales.service';
import { PermisosService } from 'src/app/shared/services/operation/permisos.service';
import { Credencial } from 'src/app/shared/model/credencial';
import { SistemaService } from 'src/app/shared/services/operation/sistema.services';
import { KeycloakService } from 'src/app/shared/services/keycloak/keycloak.service';
import { Facultad } from 'src/app/shared/model/facultad';
import { SkeletonDashboardComponent } from '../skeleton-dashboard/skeleton-dashboard.component';


@Component({
  selector: 'app-menu-lateral',
  templateUrl: './menu-lateral.component.html'
})
export class MenuLateralComponent implements OnInit {
  @Input('dataMenu') menu: any[];

  menuItems: any[];
  credencial: Credencial = new Credencial();

  constructor(private credencialService: CredencialesService,
              private permisoService: PermisosService,
              private sistemaService: SistemaService,
              private skeletonDashboardComponent: SkeletonDashboardComponent) {

    this.skeletonDashboardComponent.getIsCollapsedEvent().subscribe((isCollapsed) =>{
      if (this.menuItems !== undefined
        && this.menuItems != null
        && this.menuItems !== []) {
        this.menuItems.forEach(menuItem => {
          menuItem.isCollapsed = isCollapsed;
        });
      }
    });
  }

  ngOnInit() {
    this.credencial.token = KeycloakService.getTokenAuth();
    this.permisoService.obtenerPermisos(KeycloakService.getUsername(), environment.sistema)
        .subscribe(data => {
              if (data !== undefined) {
                this.filterMenu(data.data[0].facultades);
                this.credencial.rol = data.data[0].nombreCorto;
                this.credencial.facultades = data.data[0].facultades;
                this.sistemaService.obtenerSistemas(KeycloakService.getUsername())
                    .subscribe(data => {
                      if (data !== undefined) {
                        this.credencial.sistemas = data.data;
                      }
                    });
              }
            },
            error1 => {
              console.log('ERROR');
              console.log(error1);
            });

    this.credencialService.setCredencial(this.credencial);
  }

  filterMenu(usrRol: Facultad[]) {
    const itemsFiltred: any[] = [];
    const permisos: any = config.CONTENIDO_ROLES_PERMISOS;
    const items: any[] = [];
    this.menu.forEach( menuItem => {
      items.push(Object.assign({}, menuItem));
    });

    items.map((x) => { x.childs = []; });

      this.menu.forEach(item => {
        usrRol.forEach(facultad => {
        if (!item.childs) {
          permisos[item.modulo][item.accion].permisosRol.forEach(rol => {
            if (rol === facultad.nombre) {
              itemsFiltred.push(item);
            }
          });
        } else if (item.childs.length !== 0) {
          const childsFiltred: any[] = [];
          item.childs.forEach(child => {
            permisos[child.modulo][child.accion].permisosRol.forEach(rol => {
              if (rol === facultad.nombre) {
                  childsFiltred.push(child);
              }
            });
          });

          if ( childsFiltred.length !== 0) {
            items.forEach(it => {
              if (item.nombre === it.nombre) {
                childsFiltred.forEach( childFilt => {
                  it.childs.push(childFilt);
                });
              }
            });
          }
        }
      });
    });

    items.forEach( finalItem => {
      if (finalItem.childs.length === 0) {
        items.splice(items.indexOf(finalItem), 1);
      }
    });
    this.menuItems = items;

  }


}
