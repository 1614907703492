
const VERSIONES_APP: any[] = [
  {
    version: '1.0.0',
    revision: '0000001',
    descripcion: 'Primer versión app'
  }
];

export const CONTENIDO_ROLES_PERMISOS: any = {
  'consulta': {
    permisosRol: ['consulta'],
    'consultaUsuarios': {
      permisosRol: ['consulta']
    },
    'consultaBitacora': {
      permisosRol: ['consulta']
    }
  },
  'administracion': {
    'altaUsuarios': {
      permisosRol: ['alta']
    },
    'adminUsuarios': {
      permisosRol: ['modifica']
    }
  }
};

export const FACULTADES: any[] = [
  {
    nombre: 'consultar',
    permisosRol: ['consulta']
  },
  {
    nombre: 'editar',
    permisosRol: ['modifica']
  },
  {
    nombre: 'eliminar',
    permisosRol: ['modifica']
  }
];

export const MENU: any[] = [
  {
    nombre: 'Administración',
    idCollapsed: 'collapsedAdmin',
    isCollapsed: false,
    icon: 'fi-stack',
    url: '/main-dashboard',
    path: null,
    outlet: null,
    childs: [
      {
        nombre: 'Facultades',
        url: null,
        path: 'admin-usuarios',
        outlet: 'contentDashboard',
        modulo: 'administracion',
        accion: 'adminUsuarios'
      },
      {
        nombre: 'Alta de Usuario Administrador',
        url: null,
        path: 'flujo-alta',
        outlet: 'contentDashboard',
        modulo: 'administracion',
        accion: 'altaUsuarios'
      },
      {
        nombre: 'Alta de Usuario Servicio',
        url: null,
        path: 'flujo-alta-servicio',
        outlet: 'contentDashboard',
        modulo: 'administracion',
        accion: 'altaUsuarios'
      }
    ],
    modulo: 'administracion',
    accion: null
  },
  {
    nombre: 'Consulta',
    idCollapsed: 'collapsedConsulta',
    isCollapsed: false,
    icon: 'fi-search',
    url: '/main-dashboard',
    path: null,
    outlet: null,
    childs: [
      {
        nombre: 'Usuarios',
        url: null,
        path: 'consulta-usuarios',
        outlet: 'contentDashboard',
        modulo: 'consulta',
        accion: 'consultaUsuarios'
      },
      {
        nombre: 'Bitácora',
        url: null,
        path: 'consulta-bitacora',
        outlet: 'contentDashboard',
        modulo: 'consulta',
        accion: 'consultaBitacora'
      }

    ],
    modulo: 'consulta',
    accion: null
  }
];

export const FLUJO_ALTA_USUARIO: any[] = [
  {
    id: 1,
    nombre: 'Datos del usuario',
    path: 'datos-usuario',
    icon: 'icon-user'
  },
  {
    id: 2,
    nombre: 'Roles del usuario',
    path: 'roles-usuario',
    icon: 'icon-people'
  },
  {
    id: 3,
    nombre: 'Resumen del usuario',
    path: 'resumen-usuario',
    icon: 'icon-list'
  }
];

export const FLUJO_ALTA_USUARIO_SERVICIO: any[] = [
  {
    id: 1,
    nombre: 'Datos del usuario',
    path: 'datos-usuario-servicio',
    icon: 'icon-user'
  },
  {
    id: 2,
    nombre: 'Roles del usuario',
    path: 'roles-usuario-servicio',
    icon: 'icon-people'
  },
  {
    id: 3,
    nombre: 'Resumen del usuario',
    path: 'resumen-usuario-servicio',
    icon: 'icon-list'
  }
];

export const ULTIMA_VERSION_APP: any = VERSIONES_APP[VERSIONES_APP.length - 1];

export class Configurations {
}
