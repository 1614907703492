import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {fromPromise} from 'rxjs/internal-compatibility';
import {UserLogin} from '../../model/common/usuario-login';
import {UserRol} from '../../model/common/rol-usuario';
import * as Keycloak from 'keycloak-js';
import { environment } from 'src/environments/environment';

/**
 * @author Vicente Mendoza [AMIS-TI]
 */
@Injectable({
    providedIn: 'root'
})
export class KeycloakService {

    static auth: any = {};
    minValidity = 5;

    constructor() {
    }

    static logout() {
        KeycloakService.auth.loggedIn = false;
        KeycloakService.auth.authz = null;
        window.location.href = KeycloakService.auth.logoutUrl;

    }

    static init(): Promise<any> {
        console.log('INIT KEYCLOAK ---->');
        const keycloakAuth: any = Keycloak({
            url: environment.keycloak.url,
            realm: environment.keycloak.realm,
            clientId: environment.keycloak.clientId,
            'ssl-required': 'none',
            'public-client': true
        });

        KeycloakService.auth.loggedIn = false;

        return new Promise((resolve, reject) => {
            keycloakAuth.init({
                onLoad: 'login-required',
                checkLoginIframe: false
            })
                .success(() => {
                    KeycloakService.auth.loggedIn = true;
                    KeycloakService.auth.authz = keycloakAuth;
                    KeycloakService.auth.logoutUrl = `${keycloakAuth.authServerUrl}realms/${keycloakAuth.realm}/protocol/openid-connect/logout?redirect_uri=${document.baseURI}`;
                    console.log(`logout url: ${KeycloakService.auth.logoutUrl}`);
                    resolve();
                })
                .error(() => {
                    reject();
                });
        });
    }

    static getTokenAuth(): string {
        return KeycloakService.auth.authz.token;
    }

    tokenisExpired(): boolean {
        return KeycloakService.auth.authz.isTokenExpired(this.minValidity);
    }

    static getUsername(): string {
        return KeycloakService.auth.authz.tokenParsed.preferred_username;
    }

    static getEmail(): string {
        return KeycloakService.auth.authz.tokenParsed.email;
    }

    static getFullName(): string {
        return KeycloakService.auth.authz.tokenParsed.name;
    }

    static getUserKeycloak(): UserLogin {
        return new UserLogin(
            this.getFullName().replace('+', ' '),
            this.getUsername(),
            this.getEmail(),
            new UserRol('', '')
        );
    }

    _getToken(): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            if (KeycloakService.auth.authz.token) {
                KeycloakService.auth.authz
                    .updateToken(this.minValidity)
                    .success(function (refreshed) {
                        if (refreshed) {
                            console.log('Token was successfully refreshed');
                        }
                        resolve(<string>KeycloakService.auth.authz.token);
                    })
                    .error(() => {
                        reject('token refresh error');
                    });
            } else {
                reject('errror login');
            }
        });
    }

    refreshToken(): Observable<any> {
        return fromPromise(this._getToken());
    }

    getToken() {
        return KeycloakService.auth.authz.token;
    }

}
