import {Sistema} from './sistema';
import {Usuario} from './usuario';
import {Facultad} from './facultad';

export class Credencial {

    usuario: Usuario;
    token: string;
    sistemas: Array<Sistema>;
    rol: string;
    facultades: Array<Facultad>;

}
