import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {UsuariosComponent} from '../consulta/usuarios/usuarios.component';
import {BitacoraComponent} from '../consulta/bitacora/bitacora.component';
import {FacultadesComponent} from '../administracion/facultades/facultades.component';
import {ConsultaFacultadesComponent} from '../consulta/facultades/facultades.component';
import {AdminUsuariosComponent} from '../administracion/usuarios/usuarios.component';
import {FlujoAltaComponent} from '../alta-usuarios/flujo-alta/flujo-alta.component';
import {DatosUsuarioComponent} from '../alta-usuarios/datos-usuario/datos-usuario.component';
import {RolesUsuarioComponent} from '../alta-usuarios/roles-usuario/roles-usuario.component';
import {ResumenUsuarioComponent} from '../alta-usuarios/resumen-usuario/resumen-usuario.component';
import {SistemasComponent} from '../administracion/sistemas/sistemas.component';
import { SkeletonDashboardComponent } from '../global/skeleton-dashboard/skeleton-dashboard.component';
import { FlujoAltaServicioComponent } from '../alta-usuarios/flujo-alta-servicio/flujo-alta-servicio.component';
import { DatosUsuarioServicioComponent } from '../alta-usuarios/datos-usuario-servicio/datos-usuario-servicio.component';
import { RolesUsuarioServicioComponent } from '../alta-usuarios/roles-usuario-servicio/roles-usuario-servicio.component';
import { ResumenUsuarioServicioComponent } from '../alta-usuarios/resumen-usuario-servicio/resumen-usuario-servicio.component';

const routes: Routes = [
  {
    path: 'main-dashboard',
    component: SkeletonDashboardComponent,
    children: [
      {
        path: 'consulta-usuarios',
        component: UsuariosComponent,
        data: { breadcrumb: 'Consulta Usuarios' }
      },
      {
        path: 'consulta-bitacora',
        component: BitacoraComponent,
        data: { breadcrumb: 'Consulta Bitácora' }
      },
      {
        path: 'admin-facultades',
        component: FacultadesComponent,
        data: { breadcrumb: 'Administración Facultades' }
      },
      {
        path: 'consulta-facultades',
        component: ConsultaFacultadesComponent,
        data: { breadcrumb: 'Consulta Facultades' }
      },
      {
        path: 'admin-sistemas',
        component: SistemasComponent,
        data: { breadcrumb: 'Administración Sistemas' }
      },
      {
        path: 'flujo-alta',
        component: FlujoAltaComponent,
        data: { breadcrumb: 'Alta Usuario' },
        children: [
          {
            path: 'datos-usuario',
            component: DatosUsuarioComponent,
            outlet: 'registro',
            data: { breadcrumb: 'Datos del usuario' }
          },
          {
            path: 'roles-usuario',
            component: RolesUsuarioComponent,
            outlet: 'registro',
            data: { breadcrumb: 'Roles del usuario' }
          },
          {
            path: 'resumen-usuario',
            component: ResumenUsuarioComponent,
            outlet: 'registro',
            data: { breadcrumb: 'Resumen del usuario' }
          }
        ]
      },
      {
        path: 'flujo-alta-servicio',
        component: FlujoAltaServicioComponent,
        data: { breadcrumb: 'Alta Usuario' },
        children: [
          {
            path: 'datos-usuario-servicio',
            component: DatosUsuarioServicioComponent,
            outlet: 'registro',
            data: { breadcrumb: 'Datos del usuario' }
          },
          {
            path: 'roles-usuario-servicio',
            component: RolesUsuarioServicioComponent,
            outlet: 'registro',
            data: { breadcrumb: 'Roles del usuario' }
          },
          {
            path: 'resumen-usuario-servicio',
            component: ResumenUsuarioServicioComponent,
            outlet: 'registro',
            data: { breadcrumb: 'Resumen del usuario' }
          }
        ]
      },
      {
        path: 'admin-usuarios',
        component: AdminUsuariosComponent,
        data: { breadcrumb: 'Administración Usuarios' }
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class InicioRoutingModule { }
