import {UserRol} from './rol-usuario';

export class UserLogin {
  name: string;
  username: string;
  email: string;
  rol: UserRol;

  constructor(name: string, username: string, email: string, rol: UserRol) {
    this.name = name;
    this.username = username;
    this.email = email;
    this.rol = rol;
  }
}
