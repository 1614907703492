import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Sistema} from '../../model/sistema';
import { environment } from 'src/environments/environment';


@Injectable()
export class SistemaService {
  constructor(private http: HttpClient) { }

  sistemas: Array<Sistema> = [];

  obtenerDatosUsuario(usrname: string){
    const predicado = '/sistemas/' + environment.sistema + '/usuarios/' + usrname;
    const endpoint = environment.seguridadEndpoint + predicado;
    return this.http.get<any>(endpoint);
  }

  obtenerSistemas(usrname: string) {
    const predicado = '/usuarios/' + usrname + '/sistemas';
    const endpoint = environment.seguridadEndpoint + predicado;
    return this.http.get<any>(endpoint);
  }

  borrarSistemas(usrname: string, sistema: number) {
    const predicado = '/sistemas/' + sistema + '/usuarios/' + usrname ;
    const endpoint = environment.seguridadEndpoint + predicado;
    return this.http.delete<any>(endpoint);
  }


}
