export class Rol {

  idRol: number;
  nombreCorto: string;
  descripcion: string;
  esAdministrador: boolean;
  estatus: boolean;

  constructor(id: number) {
    this.idRol = id;
  }

}
