import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {FlujoAltaComponent} from './flujo-alta/flujo-alta.component';
import {DatosUsuarioComponent} from './datos-usuario/datos-usuario.component';
import {RolesUsuarioComponent} from './roles-usuario/roles-usuario.component';
import {ResumenUsuarioComponent} from './resumen-usuario/resumen-usuario.component';
import { SkeletonDashboardComponent } from '../global/skeleton-dashboard/skeleton-dashboard.component';
import { FlujoAltaServicioComponent } from './flujo-alta-servicio/flujo-alta-servicio.component';
import { DatosUsuarioServicioComponent } from './datos-usuario-servicio/datos-usuario-servicio.component';
import { RolesUsuarioServicioComponent } from './roles-usuario-servicio/roles-usuario-servicio.component';
import { ResumenUsuarioServicioComponent } from './resumen-usuario-servicio/resumen-usuario-servicio.component';


const routes: Routes = [
  // {
  //   path: 'main-dashboard',
  //   component: SkeletonDashboardComponent,
    // children: [
    //   {
    //     path: 'flujo-alta',
    //     component: FlujoAltaComponent,
    //     data: { breadcrumb: 'Alta Usuario' },
    //     children: [
    //       {
    //         path: 'datos-usuario',
    //         component: DatosUsuarioComponent,
    //         outlet: 'registro',
    //         data: { breadcrumb: 'Datos del usuario' }
    //       },
    //       {
    //         path: 'roles-usuario',
    //         component: RolesUsuarioComponent,
    //         outlet: 'registro',
    //         data: { breadcrumb: 'Roles del usuario' }
    //       },
    //       {
    //         path: 'resumen-usuario',
    //         component: ResumenUsuarioComponent,
    //         outlet: 'registro',
    //         data: { breadcrumb: 'Resumen del usuario' }
    //       }
    //     ]
    //   },
    //   {
    //     path: 'flujo-alta-servicio',
    //     component: FlujoAltaServicioComponent,
    //     data: { breadcrumb: 'Alta Usuario' },
    //     children: [
    //       {
    //         path: 'datos-usuario-servicio',
    //         component: DatosUsuarioServicioComponent,
    //         outlet: 'registro',
    //         data: { breadcrumb: 'Datos del usuario' }
    //       },
    //       {
    //         path: 'roles-usuario-servicio',
    //         component: RolesUsuarioServicioComponent,
    //         outlet: 'registro',
    //         data: { breadcrumb: 'Roles del usuario' }
    //       },
    //       {
    //         path: 'resumen-usuario-servicio',
    //         component: ResumenUsuarioServicioComponent,
    //         outlet: 'registro',
    //         data: { breadcrumb: 'Resumen del usuario' }
    //       }
    //     ]
    //   }
    // ]
  // }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AltaUsuariosRoutingModule { }
