import {Component, OnInit} from '@angular/core';
import {FLUJO_ALTA_USUARIO} from '../../shared/services/configurations';
import {Router} from '@angular/router';
import {CredencialesService} from '../../shared/services/operation/credenciales.service';
import {KeycloakService} from '../../shared/services/keycloak/keycloak.service';

@Component({
  selector: 'app-flujo-alta',
  templateUrl: './flujo-alta.component.html',
  styleUrls: ['./flujo-alta.component.scss']
})
export class FlujoAltaComponent implements OnInit {

  itemsFlujo: any[] = FLUJO_ALTA_USUARIO;

  constructor(private router: Router,
              private credencialService: CredencialesService) { }

  ngOnInit() {
    if (this.credencialService.validaLogin()) {
      this.router.navigate(['/main-dashboard/flujo-alta', {outlets: {registro: 'datos-usuario'}}]);
    } else {
      KeycloakService.logout();
    }
  }

  irASeccionFlujo(eve){
    this.router.navigate(['/main-dashboard/flujo-alta', { outlets: { registro: [eve.nextId] } }]);
  }
}
