import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FLUJO_ALTA_USUARIO_SERVICIO } from 'src/app/shared/services/configurations';
import { KeycloakService } from 'src/app/shared/services/keycloak/keycloak.service';
import { CredencialesService } from 'src/app/shared/services/operation/credenciales.service';

@Component({
  selector: 'app-flujo-alta-servicio',
  templateUrl: './flujo-alta-servicio.component.html',
  styleUrls: ['./flujo-alta-servicio.component.scss']
})
export class FlujoAltaServicioComponent implements OnInit {

  itemsFlujo: any[] = FLUJO_ALTA_USUARIO_SERVICIO;

  constructor(private router: Router,
              private credencialService: CredencialesService) { }

  ngOnInit() {
    if (this.credencialService.validaLogin()) {
      this.router.navigate(['/main-dashboard/flujo-alta-servicio', {outlets: {registro: 'datos-usuario-servicio'}}]);
    } else {
      KeycloakService.logout();
    }
  }

  irASeccionFlujo(eve) {
    this.router.navigate(['/main-dashboard/flujo-alta-servicio', { outlets: { registro: [eve.nextId] } }]);
  }

}
